import employerOfTheDayItemButtonClick, {
    HhtmSourceLabel,
} from '@hh.ru/analytics-js-events/build/xhh/common/main/employer_of_the_day_item_button_click';
import urlParser from 'bloko/common/urlParser';

import { CompanyOfTheDay } from 'src/models/companiesOfTheDay';
import { Locale } from 'src/models/locale.types';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

export interface MobileCompanyItemInterface {
    company: CompanyOfTheDay;
    isLast: boolean;
}

export interface CompanyItemInterface {
    company: CompanyOfTheDay;
    analyticsEvent?: string;
    itemClassName?: string;
    itemWrapperClassName?: string;
}

export const sendAnalytics = (company: CompanyOfTheDay, hhtmSourceLabel: HhtmSourceLabel): void => {
    employerOfTheDayItemButtonClick({ employerId: `${company.id}`, hhtmSourceLabel });
};

export const getCompanyLink = (
    company: CompanyOfTheDay,
    locale: Locale,
    location: WorkInCompanyLocation,
    isZP = false
): string =>
    isZP
        ? `${locale.protocolHost}/employer/${company.id}`
        : `https://content.hh.ru/api/v1/click?${urlParser.stringify({
              contentId: company.workInCompanyId,
              placeId: company.placeId,
              employerId: company.id,
              domainAreaId: locale.domainAreaId,
              host: locale.protocolHost,
              location,
          })}`;
