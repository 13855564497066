import { FC } from 'react';

import { Card, VSpacing } from '@hh.ru/magritte-ui';

import EmployerReviewsWidget from 'src/components/EmployerReviews/WidgetOnMain';
import { useReviewsWidgetHasContent } from 'src/components/EmployerReviews/WidgetOnMain/useReviewsWidgetHasContent';
import EmployerReviewsSmallWidget from 'src/components/EmployerReviews/WidgetSmall';
import { useEmployerReviewsSmallWidgetHasContent } from 'src/components/EmployerReviews/WidgetSmall/useEmployerReviewsSmallWidgetHasContent';
import RenewalServiceWidget, { useRenewalServiceHasContent } from 'src/components/RenewalService/RenewalServiceWidget';
import { useIsRussia } from 'src/hooks/useCountries';

import MobileAppBanner from 'src/components/IndexPageEmployer/components/AdditionalServices/MobileAppBanner';
import ReviewsSmallWidgetWrapper from 'src/components/IndexPageEmployer/components/AdditionalServices/ReviewsSmallWidgetWrapper';

const AdditionalServices: FC = () => {
    const renewalServiceHasContent = useRenewalServiceHasContent();
    const reviewsWidgetMasContent = useReviewsWidgetHasContent();
    const employerReviewsSmallWidgetHasContent = useEmployerReviewsSmallWidgetHasContent(false);
    const isRussia = useIsRussia();

    if (!isRussia) {
        return null;
    }

    let widget = <MobileAppBanner />;

    if (renewalServiceHasContent) {
        widget = <RenewalServiceWidget />;
    } else if (reviewsWidgetMasContent) {
        widget = <EmployerReviewsWidget />;
    }
    return (
        <>
            <Card verticalStretched stretched>
                {widget}
            </Card>
            {employerReviewsSmallWidgetHasContent && (
                <>
                    <VSpacing default={24} />
                    <EmployerReviewsSmallWidget onlyTrusted={false} Wrapper={ReviewsSmallWidgetWrapper} />
                </>
            )}
        </>
    );
};

export default AdditionalServices;
