import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Button, Text, Card } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';
import EMPLOYER_CATEGORIES from 'src/utils/employer/categories';

import styles from './company-confirmation.less';

const CompanyConfirmation = ({ trls, companyCategory }) => {
    const isCompany = companyCategory === EMPLOYER_CATEGORIES.COMPANY;

    const handleUploadClick = () => {
        Analytics.sendHHEventButtonClick('upload-documents', {
            goal: isCompany ? 'clicked-company-confirmation' : 'clicked-not-company-confirmation',
        });
    };

    useEffect(() => {
        Analytics.sendHHEvent(
            'element_shown',
            { goal: isCompany ? 'showed-company-confirmation' : 'showed-not-company-confirmation' },
            {
                elementName: 'company-confirmation',
            }
        );
    }, [isCompany]);

    return (
        <div className={styles.cardWrapper}>
            <Card stretched verticalStretched showBorder borderRadius={24} padding={12} data-qa="company-confirmation">
                <div className={styles.content}>
                    <div className={styles.text}>
                        <Text typography="title-5-semibold">{trls[CompanyConfirmation.trls.title]}</Text>
                        <Text typography="label-3-regular" style="secondary">
                            {trls[CompanyConfirmation.trls.description]}
                        </Text>
                    </div>
                    <Button
                        Element={Link}
                        to="/employer/documents"
                        onClick={handleUploadClick}
                        data-qa="company-confirmation-completeRegistration"
                        style="neutral"
                        mode="secondary"
                        size="medium"
                        stretched
                    >
                        {trls[CompanyConfirmation.trls.action]}
                    </Button>
                </div>
            </Card>
        </div>
    );
};

CompanyConfirmation.trls = {
    description: 'advice.employer.completeRegistration.description.notCompany',
    title: 'advice.employer.completeRegistration.title',
    action: 'advice.employer.completeRegistration.action',
};

CompanyConfirmation.propTypes = {
    trls: PropTypes.object,
    show: PropTypes.bool,
    companyCategory: PropTypes.string,
};

export default translation(CompanyConfirmation);
