import { Card } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';

import styles from 'src/components/IndexPageEmployer/components/AdditionalServices/MobileAppBanner/styles.less';

const TrlKeys = {
    close: 'employer.index.additionalServices.mobileBanner.close',
    qrCodeAltText: 'employer.banner.suitable-gifts-promo.qr-code.alt-text',
};

const CardBack: TranslatedComponent = ({ trls }) => {
    return (
        <div className={styles.cardBack}>
            <div className={styles.contentContainer}>
                <Card padding={8} style="neutral" borderRadius={12}>
                    <div data-qa="card-back-content" className={styles.qrCodeContainer}>
                        <StaticImg
                            className={styles.qrCode}
                            path="/images/hh/hh-business/qr-code-transparent.png"
                            alt={trls[TrlKeys.qrCodeAltText]}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default translation(CardBack);
