import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { KakdelaLinkMode } from 'src/models/kakdela';

import Card, { CardType } from 'src/components/IndexPageApplicant/Actions/Card';

const TrlKeys = {
    defaultTitle: 'kakdela.banner.title',
    linkText: 'kakdela.banner.link',
};

const KakdelaBanner: TranslatedComponent = ({ trls }) => {
    const banner = useSelector((state) => state.kakdelaBanner);

    if (!banner) {
        return null;
    }

    const handleClick = () => {
        const target = banner.linkMode === KakdelaLinkMode.Internal ? '_self' : '_blank';
        window.open(banner.passingLink, target);
    };

    return (
        <Card
            dataQa="applicant-index-nba-action_kakdela"
            caption={banner.bannerText || trls[TrlKeys.defaultTitle]}
            linkText={trls[TrlKeys.linkText]}
            type={CardType.KakdelaBanner}
            onClick={handleClick}
        />
    );
};

export default translation(KakdelaBanner);
