import EmployerRatingTeaser from 'src/components/EmployerRatingTeaser';
import MessengersBots from 'src/components/MessengersBots';
import { useSelector } from 'src/hooks/useSelector';

interface InternalPromoBlockProps {
    isInline?: boolean;
}

const InternalPromoBlock: React.FC<InternalPromoBlockProps> = ({ isInline }) => {
    const showEmployerRatingBlock = useSelector((state) => state.showEmployerRatingBlock);

    if (showEmployerRatingBlock) {
        return <EmployerRatingTeaser isInline={isInline} />;
    }

    return <MessengersBots isInline={isInline} />;
};

export default InternalPromoBlock;
