import { useState } from 'react';
import classnames from 'classnames';

import employerMobileBannerClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_mobile_banner_click';
import employerMobileBannerShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_mobile_banner_element_shown';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Card } from '@hh.ru/magritte-ui';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';

import CardBack from 'src/components/IndexPageEmployer/components/AdditionalServices/MobileAppBanner/CardBack';
import CardFront from 'src/components/IndexPageEmployer/components/AdditionalServices/MobileAppBanner/CardFront';

import styles from './styles.less';

const TrlKeys = {
    close: 'employer.index.additionalServices.mobileBanner.close',
    download: 'employer.index.additionalServices.mobileBanner.download',
};

const MobileAppBanner: TranslatedComponent = ({ trls }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleToggleFlipped = () => setIsFlipped((prev) => !prev);

    const buttonName = isFlipped ? 'close_mobile_banner' : 'open_mobile_banner';
    return (
        <Card verticalStretched stretched showBorder borderRadius={24}>
            <ElementShownAnchor className={styles.cardContainer} fn={employerMobileBannerShown}>
                <div className={styles.cardFlipperContainer}>
                    <div
                        data-qa="card-flipper-content"
                        className={classnames(styles.cardFlipper, { [styles.cardFlipperFlipped]: isFlipped })}
                    >
                        <CardFront />
                        <CardBack />
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        onClick={() => {
                            employerMobileBannerClick({
                                buttonName,
                            });
                            handleToggleFlipped();
                        }}
                        size="small"
                        stretched
                        mode="secondary"
                        data-qa={buttonName}
                        style="neutral"
                    >
                        {isFlipped ? trls[TrlKeys.close] : trls[TrlKeys.download]}
                    </Button>
                </div>
            </ElementShownAnchor>
        </Card>
    );
};

export default translation(MobileAppBanner);
