import { FC, PropsWithChildren } from 'react';

import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import Link, { LinkKind } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';

import { ArticleItemWithTopic } from 'src/models/article.types';

interface UsefulLinksProps {
    items: ArticleItemWithTopic[] | null;
    title: string;
    withSeparator?: boolean;
    isEmployerContext?: boolean;
}

const UsefulLinks: FC<UsefulLinksProps & PropsWithChildren> = ({ items, title, withSeparator = false }) => {
    return (
        <>
            <Gap top>
                <div className="index-useful">
                    <div className="index-news-box__header">
                        <H2 Element="h3">{title}</H2>
                        <VSpacing base={4} />
                    </div>
                    <ul>
                        {items?.map(({ topic, href }) => (
                            <li className="useful-link" key={href}>
                                <Link href={href} kind={LinkKind.Tertiary}>
                                    {topic}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    {withSeparator && (
                        <div className="index-news-box__separator">
                            <div className="separator" />
                        </div>
                    )}
                </div>
            </Gap>
        </>
    );
};

export default UsefulLinks;
