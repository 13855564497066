import { FC, useCallback, PropsWithChildren } from 'react';

import Analytics from '@hh.ru/analytics-js';

interface AppLinkProps {
    name: string;
    href: string;
    analyticCategory: string;
    analyticName: string;
    analyticLabel: string;
}

const AppLink: FC<AppLinkProps & PropsWithChildren> = ({
    name,
    href,
    analyticCategory,
    analyticName,
    analyticLabel,
}) => {
    const sendAnalytics = useCallback(
        () => Analytics.sendEvent(analyticCategory, analyticName, analyticLabel),
        [analyticCategory, analyticName, analyticLabel]
    );

    return (
        <a
            className={`stay-in-touch__link stay-in-touch__link_${name}`}
            rel="noopener noreferrer"
            target="_blank"
            href={href}
            onMouseDown={sendAnalytics}
            onKeyDown={sendAnalytics}
        />
    );
};

export default AppLink;
