import PropTypes from 'prop-types';

import { Text } from '@hh.ru/magritte-ui';

import SearchCriterion from 'src/components/IndexPageEmployer/components/LastSearches/SearchCriterion';

const mapEntitiesToCriteries = (entities) => {
    if (!entities) {
        return null;
    }

    return entities.map(({ id, text }) => <SearchCriterion key={id}>{text}</SearchCriterion>);
};

const SearchDetails = ({ searchCriteria = {} }) => {
    return (
        <Text typography="paragraph-3-regular" style="secondary" data-qa="last-searches-details">
            {mapEntitiesToCriteries(searchCriteria.area)}
            {mapEntitiesToCriteries(searchCriteria.employment)}
            {mapEntitiesToCriteries(searchCriteria.schedule)}
        </Text>
    );
};

const baseEntityWithTrl = PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
});

SearchDetails.propTypes = {
    trls: PropTypes.object,
    searchCriteria: PropTypes.shape({
        area: PropTypes.arrayOf(baseEntityWithTrl),
        employment: PropTypes.arrayOf(baseEntityWithTrl),
        schedule: PropTypes.arrayOf(baseEntityWithTrl),
    }),
};

export default SearchDetails;
