import { FC, ElementType, PropsWithChildren, ReactNode } from 'react';

import { Skeleton, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';

import { useEmployerReviewsSmallWidgetCanBeRendered } from 'src/components/EmployerReviews/WidgetSmall/useEmployerReviewsSmallWidgetHasContent';

interface EmployerReviewsSmallWidgetProps {
    Wrapper?: ElementType;
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    onlyTrusted?: boolean;
    white?: boolean;
}

const DefaultWrapper: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

const WIDGET_DEFAULT_PLACE_NAME = 'employerReviewsSmall';
const WIDGET_WHITE_PLACE_NAME = 'employerReviewsSmallWhite';

const EmployerReviewsSmallWidget: FC<EmployerReviewsSmallWidgetProps> = ({
    Wrapper = DefaultWrapper,
    isRedesign,
    isMagritteVacancy,
    white = false,
    onlyTrusted = true,
}) => {
    const canBeRendered = useEmployerReviewsSmallWidgetCanBeRendered(onlyTrusted);

    if (!canBeRendered) {
        return null;
    }

    const place = white ? WIDGET_WHITE_PLACE_NAME : WIDGET_DEFAULT_PLACE_NAME;
    let spacing: ReactNode = null;
    if (isMagritteVacancy) {
        spacing = <MagritteVSpacing default={4} />;
    } else if (isRedesign) {
        spacing = <VSpacing base={4} />;
    }

    return (
        <Wrapper>
            {spacing}
            <ContainerForMicroFrontend
                place={place}
                preloadContent={!white && <Skeleton height={24} width={200} loading borderRadius={8} />}
            />
        </Wrapper>
    );
};

export default EmployerReviewsSmallWidget;
