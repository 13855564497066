import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';

import styles from 'src/components/IndexPageEmployer/components/AdditionalServices/MobileAppBanner/styles.less';

const TrlKeys = {
    download: 'employer.index.additionalServices.mobileBanner.download',
    title: 'employer.index.additionalServices.mobileBanner.title',
    text: 'employer.index.additionalServices.mobileBanner.text',
};

const CardFront: TranslatedComponent = ({ trls }) => {
    return (
        <div className={styles.cardFront}>
            <div data-qa="card-front-content" className={styles.contentContainer}>
                <StaticImg path="/images/hh/hh-business/logo-small.svg" />
                <VSpacing default={16} />
                <Text typography="subtitle-1-semibold">{trls[TrlKeys.title]}</Text>
                <VSpacing default={8} />
                <Text style="secondary" typography="label-3-regular">
                    {trls[TrlKeys.text]}
                </Text>
            </div>
        </div>
    );
};

export default translation(CardFront);
