import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import Link from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';

const TrlKeys = {
    confirmText: 'employer.reg.form.confirm.text.new',
    confirmLinkText: 'newEmployer.rules.link',
    hh: {
        confirm: 'index.anonymous.signup.confirmAgreement',
        rules: 'index.anonymous.signup.confirmAgreement.rules',
        policy: 'index.anonymous.signup.confirmAgreement.policy',
    },
};

const Agreement: TranslatedComponent = ({ trls }) => {
    const isHHRu = useIsHhru();
    const agreementUrl = useSelector((state) => state.authUrl?.['employer-agreement']);
    const policyUrl = useSelector((state) => state.authUrl?.['policy-url']);

    const linkProps = {
        inline: true,
        Element: SpaLink,
        target: '_blank',
    } as const;

    const content = isHHRu
        ? formatToReactComponent(trls[TrlKeys.hh.confirm], {
              '{0}': (
                  <Link {...linkProps} to={policyUrl || ''}>
                      {trls[TrlKeys.hh.policy]}
                  </Link>
              ),
              '{1}': (
                  <Link {...linkProps} to={agreementUrl || ''}>
                      {trls[TrlKeys.hh.rules]}
                  </Link>
              ),
          })
        : formatToReactComponent(trls[TrlKeys.confirmText], {
              '{0}': (
                  <Link {...linkProps} to={agreementUrl || ''}>
                      {trls[TrlKeys.confirmLinkText]}
                  </Link>
              ),
          });

    return (
        <Text importance={TextImportance.Secondary} size={TextSize.Small}>
            {content}
        </Text>
    );
};

export default translation(Agreement);
