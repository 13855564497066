import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { CountryId } from 'src/models/countryId';

export enum HeaderType {
    Applicant = 'applicant',
    Employer = 'employer',
}

interface HeaderProps {
    type: HeaderType;
    countryId: CountryId;
}

const TrlKeys = {
    [HeaderType.Applicant]: {
        default: 'index.headers.stayInTouch.applicant',
    },
    [HeaderType.Employer]: {
        [CountryId.Belarus]: 'index.headers.stayInTouch.employer.jtb',
        default: 'index.headers.stayInTouch.employer',
    },
};

type TrlsByType = {
    [x in CountryId]?: string;
} & {
    default: string;
};

const getTrlKey = (type: HeaderType, countryId: CountryId) => {
    const trlsByHeaderType = TrlKeys[type] as TrlsByType;
    return trlsByHeaderType[countryId] || trlsByHeaderType.default;
};

const Header: TranslatedComponent<HeaderProps> = ({ trls, type, countryId }) => {
    return (
        <div className="index-stay-in-touch-header">
            <H2 Element="h3">{trls[getTrlKey(type, countryId)]}</H2>
            <VSpacing base={4} />
        </div>
    );
};

export default translation(Header);
