import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { closeAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { postSignupModalAlreadyShown, postSignupBackurl } from 'src/models/anonymousSignUp/api';

import SignupModal from 'src/components/SignupModal/Form';
import { SignupAnalyticsProvider } from 'src/components/SignupModal/contexts/SignupAnalytics';
import { SignupTrlsContextProvider } from 'src/components/SignupModal/contexts/SignupTrls';
import { redirectToFinishSignup } from 'src/components/SignupModal/lib';

const TrlKeys = {
    header: 'anonymous.signup.modal.signin.article.title',
};

const MainPageSignUpModal: TranslatedComponent = ({ trls }) => {
    const signinTrls = useMemo(() => ({ header: trls[TrlKeys.header] }), [trls]);
    const push = usePush();
    const dispatch = useDispatch();
    const opened = useSelector(({ anonymousSignUp }) => anonymousSignUp.opened && !anonymousSignUp.alreadyShown);
    const backUrl = useSelector(({ anonymousSignUp }) => anonymousSignUp.backUrl);

    const close = async () => {
        dispatch(closeAnonymousSignUpModal());
        await postSignupModalAlreadyShown();
        push(backUrl);
    };

    const signup = async () => {
        dispatch(closeAnonymousSignUpModal());

        await Promise.all([postSignupModalAlreadyShown(), postSignupBackurl(backUrl)]);

        redirectToFinishSignup();
    };

    return (
        <SignupTrlsContextProvider signin={signinTrls}>
            <SignupAnalyticsProvider hhtmSource="main_authorization_popup">
                <SignupModal opened={opened} onClose={close} onSignup={signup} />
            </SignupAnalyticsProvider>
        </SignupTrlsContextProvider>
    );
};

export default translation(MainPageSignUpModal);
