import { useDispatch } from 'react-redux';

import { usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale, ButtonType } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import Notification from 'bloko/blocks/notificationManager/Notification';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { updateRsvUserCompetenciesResult } from 'src/models/rsvUserCompetencies';

const TrlKeys = {
    close: 'rsvUserCompetenciesNotification.close',
    title: 'rsvUserCompetenciesNotification.title',
    subtitle: 'rsvUserCompetenciesNotification.subtitle',
    toProfile: 'rsvUserCompetenciesNotification.toProfile',
};

export enum RedirectLinks {
    LastResume = '/resume/',
    Resumes = '/applicant/resumes',
    Main = '/',
    ScrollTo = 'scrollTo',
}

const RsvUserCompetenciesNotification: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const applicantLatestResume = useSelector(({ applicantLatestResume }) => applicantLatestResume);
    const { finished } = useSelector(({ applicantInfo }) => applicantInfo);
    const push = usePush();

    const handleClose = () => dispatch(updateRsvUserCompetenciesResult(null));

    const handleRedirect = () => {
        if (finished === 1 && applicantLatestResume) {
            push(`${RedirectLinks.LastResume}${applicantLatestResume.resumeHash}?${RedirectLinks.ScrollTo}=rsv`);
        } else if (finished > 1) {
            push(RedirectLinks.Resumes);
        } else {
            push(RedirectLinks.Main);
        }
        handleClose();
    };

    return (
        <Notification kind={NotificationKind.Ok} onClose={handleClose}>
            <Text strong importance={TextImportance.Primary}>
                {trls[TrlKeys.title]}
            </Text>
            <VSpacing base={1} />
            <Text importance={TextImportance.Secondary}>{trls[TrlKeys.title]}</Text>
            <VSpacing base={4} />
            <Button type={ButtonType.Button} scale={ButtonScale.Small} onClick={handleRedirect}>
                {trls[TrlKeys.toProfile]}
            </Button>
            <HSpacing base={2} />
            <Button type={ButtonType.Button} scale={ButtonScale.Small} onClick={handleClose}>
                {trls[TrlKeys.close]}
            </Button>
        </Notification>
    );
};

export default translation(RsvUserCompetenciesNotification);
