import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    error: {
        title: 'index.applicant.resumeBatchUpdate.notification.error.title',
        content: 'index.applicant.resumeBatchUpdate.notification.error.content',
    },
    success: {
        title: 'index.applicant.resumeBatchUpdate.notification.success.title',
        content: 'index.applicant.resumeBatchUpdate.notification.success.content',
    },
};

const ResumeBatchUpdateSuccess: TranslatedComponent = ({ trls }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.success.title]}</NotificationHeading>
        <p>{trls[TrlKeys.success.content]}</p>
    </>
);
export const resumeBatchUpdateSuccessNotification = {
    Element: translation(ResumeBatchUpdateSuccess),
    kind: 'ok',
    autoClose: true,
};

const ResumeBatchUpdateError: TranslatedComponent = ({ trls }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.error.title]}</NotificationHeading>
        <p>{trls[TrlKeys.error.content]}</p>
    </>
);
export const resumeBatchUpdateErrorNotification = {
    Element: translation(ResumeBatchUpdateError),
    kind: 'error',
    autoClose: true,
};
