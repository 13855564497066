import PropTypes from 'prop-types';

import { Title } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';

import SavedSearch from 'src/components/IndexPageEmployer/components/LastSearches/SavedSearch';

const SearchList = ({ trls, searches, maxHeight }) => {
    return (
        <div data-qa="last-searches" style={{ maxHeight: `${maxHeight}px` }} className="last-searches">
            <Title Element="h2" size="small" data-qa="last-searches-title">
                {trls[SearchList.trls.title]}
            </Title>

            <VSpacing base={5} />

            <ul className="last-searches__list">
                {searches.map(({ id, text, searchQuery, searchCriteria }, index) => (
                    <SavedSearch
                        key={id}
                        id={id}
                        text={text}
                        searchQuery={searchQuery}
                        searchCriteria={searchCriteria}
                        position={index + 1}
                    />
                ))}
            </ul>
        </div>
    );
};

SearchList.trls = {
    title: 'index.employerLastSearches.title',
};

SearchList.propTypes = {
    trls: PropTypes.object,
    searches: PropTypes.array.isRequired,
    maxHeight: PropTypes.number,
};

export default translation(SearchList);
