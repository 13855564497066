import { useEffect, useState } from 'react';

import Notification from 'bloko/blocks/notificationManager/Notification';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    alreadyBound: 'rsvUserCompetenciesNotification.alreadyBound',
    error: 'rsvUserCompetenciesNotification.error',
};

const RsvUserCompetenciesNotificationError: TranslatedComponent = ({ trls }) => {
    const [isRSVFail, setRSVFail] = useState('');
    useEffect(() => {
        const urlParams = new URLSearchParams(window?.location.search);
        const isFlag = urlParams.get('fail');
        const errorText = urlParams.get('error');
        if (isFlag) {
            if (errorText === 'alreadyBound') {
                setRSVFail(trls[TrlKeys.alreadyBound]);
            } else {
                setRSVFail(trls[TrlKeys.error]);
            }
        }
    }, [trls]);

    if (!isRSVFail) {
        return null;
    }

    return (
        <Notification kind={NotificationKind.Error} autoClose>
            <Text>{isRSVFail}</Text>
        </Notification>
    );
};

export default translation(RsvUserCompetenciesNotificationError);
