import { FC, useEffect, useRef } from 'react';

import { Avatar, Cell, CellText, VSpacingContainer } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import newsBoxAnalytics from 'src/components/NewsBox/analytics';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { ArticleItemWithImage } from 'src/models/article.types';

import ShowAllLink from 'src/components/IndexPageEmployer/components/Blog/ShowAllLink';

import styles from './styles.less';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

interface NewsOrArticlesContentProps {
    isArticle?: boolean;
    items: ArticleItemWithImage[] | null;
    href: string;
}

const MAXIMUM_ITEMS_COUNT = 3;

const NewsOrArticlesContent: FC<NewsOrArticlesContentProps> = ({ href, isArticle, items }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const newsBoxRef = useRef(null);
    const newsBoxItems = (items || []).slice(0, MAXIMUM_ITEMS_COUNT);
    const analyticsType = isArticle ? 'article_list.articleList' : 'news_list.newsHeader';
    const newsBoxType = isArticle ? 'article' : 'news';

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && newsBoxItems?.length && newsBoxRef.current) {
            newsBoxAnalytics.sendNewsBoxItemsShown(
                newsBoxRef.current,
                newsBoxType,
                newsBoxItems.map((item) => item.title)
            );
        }
    }, [employerMainPageAnalyticsEnabled, isArticle, newsBoxItems, newsBoxType]);

    const handleClick = () => {
        newsBoxAnalytics.sendNewsBoxTitleClick(newsBoxType);
    };

    const dataQaName = isArticle ? 'articles' : 'news';

    return (
        <div data-qa={`${dataQaName}-content`} className={styles.content} ref={newsBoxRef}>
            <VSpacingContainer default={24}>
                {newsBoxItems.map(({ title, href, image }) => {
                    const analyticsType = isArticle ? 'article_item.articleItem' : 'news_item.newsItem';

                    return (
                        <Link
                            data-qa={`${dataQaName}-link`}
                            to={href}
                            data-page-analytics-event={analyticsType}
                            key={title}
                        >
                            <Cell
                                avatar={
                                    <Avatar
                                        mode="image"
                                        size={48}
                                        aria-label={title}
                                        Element="div"
                                        image={image}
                                        placeholder="city"
                                        fallbackMode="placeholder"
                                    />
                                }
                            >
                                <CellText maxLines={2}>{title}</CellText>
                            </Cell>
                        </Link>
                    );
                })}
            </VSpacingContainer>
            <ShowAllLink
                data-qa={`${dataQaName}-show-more-link`}
                href={href}
                data-page-analytics-event={analyticsType}
                onClick={employerMainPageAnalyticsEnabled ? handleClick : undefined}
            />
        </div>
    );
};

export default NewsOrArticlesContent;
