import useFetch, { FetchRequest } from 'src/hooks/useFetch';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/price/free_service/reject_activation';

declare global {
    interface FetcherPutApi {
        [URL]: {
            queryParams: void;
            body: void;
            response: void;
        };
    }
}

const useRejectActivation = (): FetchRequest<void, void> => {
    const { fetchRequest } = useFetch<void, void>(async () => {
        const response = await fetcher.put(URL);
        return response.data;
    });

    return fetchRequest;
};

export default useRejectActivation;
