import { VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';

import ContactPhonesTempexp from 'src/components/IndexPageNewEmployer/components/ContactPhonesTempexp';
import PreparedVacancyTemplates from 'src/components/IndexPageNewEmployer/components/PreparedVacancyTemplates';
import { useSelector } from 'src/hooks/useSelector';

import BilledVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/BilledVacancy';
import RecentDraftVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/RecentDraftVacancy';
import ScheduledPublication from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/ScheduledPublication';
import VacancyStub from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/VacancyStub';
import VacancyStubTempexp from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/VacancyStubTempexp';

import styles from './dashboard-vacancy.less';

// tempexp_35925_next-line: bigHelpEnabled
const DashboardVacancy = ({ bigHelpEnabled }) => {
    const { draft, bill, giftsCount, employerRegionTrl, employerRegionResumesCount, nextDraftScheduled } = useSelector(
        (state) => state.newEmployerDashboardVacancy
    );

    const renderVacancy = () => {
        if (draft && bill) {
            return <BilledVacancy vacancy={draft} bill={bill} giftsCount={giftsCount} />;
        }

        if (draft) {
            return <RecentDraftVacancy vacancy={draft} />;
        }

        if (nextDraftScheduled) {
            return <ScheduledPublication data={nextDraftScheduled} />;
        }

        // tempexp_35925-start
        if (bigHelpEnabled) {
            return <VacancyStubTempexp />;
        }
        // tempexp_35925-end

        return (
            <VacancyStub
                giftsCount={giftsCount}
                employerRegion={employerRegionTrl}
                employerRegionResumesCount={employerRegionResumesCount}
            />
        );
    };

    return (
        <>
            <VSpacing default={40} />
            <div className={styles.dashboardVacancy}>
                <Column xs="4" s="8" m="7" l="10" container>
                    {renderVacancy()}
                    {bigHelpEnabled && <ContactPhonesTempexp />}
                </Column>
            </div>
            <PreparedVacancyTemplates />
        </>
    );
};

export default DashboardVacancy;
