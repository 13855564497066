import { NovaFilterKey } from 'src/models/novaFilters';

export const novaFiltersOrder = [
    NovaFilterKey.PartTime,
    NovaFilterKey.ExcludedText,
    NovaFilterKey.Company,
    NovaFilterKey.Resume,
    NovaFilterKey.VacancyId,
    NovaFilterKey.Compensation,
    NovaFilterKey.Area,
    NovaFilterKey.District,
    NovaFilterKey.Neighbours,
    NovaFilterKey.Metro,
    NovaFilterKey.ProfessionalRole,
    NovaFilterKey.Industry,
    NovaFilterKey.Education,
    NovaFilterKey.Experience,
    NovaFilterKey.Employment,
    NovaFilterKey.Schedule,
    NovaFilterKey.Label,
    NovaFilterKey.SearchField,
];

export default novaFiltersOrder;
