import { useEffect, ReactNode, useMemo } from 'react';

import { FormItem } from 'bloko/blocks/form';
import { H2Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import MultiAccountGroupingForm, {
    MultiAccountGroupingFormBaseProps,
} from 'src/components/ApplicantSignInSignUpFlow/MultiAccountGroupingStep/MultiAccountGroupingForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    passwordSent: 'registration.registered.passwordSent',
    letterComeSoon: 'registration.registered.letterComeSoon',
    letterMultiaccountNote: 'registration.registered.letterMultiaccountNote',
    letterHighlightedPart: 'registration.registered.letterHighlightedPart',
};

export interface MultiAccountGroupingStepProps extends MultiAccountGroupingFormBaseProps {
    setHeader: (headerContent: ReactNode) => void;
}

const MultiAccountGroupingStep: TranslatedComponent<MultiAccountGroupingStepProps> = ({
    trls,
    setHeader,
    ...formProps
}) => {
    const { login } = useSelector(({ otp }) => otp);

    const headerContent = useMemo(
        () => (
            <>
                <H2Section>
                    {format(trls[TrlKeys.passwordSent], {
                        '{0}': login as string,
                    })}
                </H2Section>
                <VSpacing base={3} />
                <p>
                    {formatToReactComponent(trls[TrlKeys.letterMultiaccountNote], {
                        '{0}': (
                            <Text Element="span" strong>
                                {trls[TrlKeys.letterHighlightedPart]}
                            </Text>
                        ),
                    })}
                </p>
                <p>{trls[TrlKeys.letterComeSoon]}</p>
            </>
        ),
        [login, trls]
    );

    useEffect(() => {
        setHeader(headerContent);
    }, [headerContent, setHeader]);

    return (
        <MultiAccountGroupingForm
            render={({ renderInput, renderError, renderSubmit }) => {
                return (
                    <>
                        <FormItem>{renderInput()}</FormItem>
                        {renderError()}
                        <VSpacing base={4} />
                        {renderSubmit({ isStretched: true })}
                    </>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(MultiAccountGroupingStep);
