import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';

import { getCompanyLink, sendAnalytics } from 'src/components/WorkInCompany/Utils';
import WorkInBanner from 'src/components/WorkInCompany/WorkInBanner';
import useCompaniesOfTheDayViewAnalytics from 'src/components/WorkInCompany/hooks/useCompaniesOfTheDayViewAnalytics';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { CompanyOfTheDay } from 'src/models/companiesOfTheDay';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

interface RainbowDropCompaniesOfTheDayProps {
    companiesOfTheDay: CompanyOfTheDay[];
}

const RainbowDropCompaniesOfTheDay: FC<RainbowDropCompaniesOfTheDayProps> = ({ companiesOfTheDay }) => {
    const locale = useSelector((state) => state.locale);

    const isZP = useIsZarplataPlatform();
    const companiesRefs = useCompaniesOfTheDayViewAnalytics(companiesOfTheDay, WorkInCompanyLocation.RainbowCatalog);

    return (
        <ul className="multiple-column-list multiple-column-list_wide">
            <li className="dashboard-tiles-drop-banner">
                <WorkInBanner />
            </li>
            {companiesOfTheDay.map((company: CompanyOfTheDay) => (
                <li className="multiple-column-list-item" key={company.id}>
                    <Text>
                        <div className="dashboard-tiles-drop-item">
                            <BlokoLink
                                to={getCompanyLink(company, locale, WorkInCompanyLocation.RainbowCatalog, isZP)}
                                Element={Link}
                                kind={LinkKind.Tertiary}
                                onClick={() => sendAnalytics(company, 'rainbow_catalog')}
                            >
                                <div
                                    className="dashboard-tiles-drop-employer"
                                    data-qa="company-of-the-day-title"
                                    ref={(ref) => {
                                        if (ref && companiesRefs.current) {
                                            companiesRefs.current[company.id] = ref;
                                        }
                                    }}
                                >
                                    <span data-qa="company-of-the-day-name">{company.companyName}</span>
                                    <span className="dashboard-tiles-drop-employer-vacancies-number">
                                        <Text importance={TextImportance.Tertiary}>{company.vacanciesNumber}</Text>
                                    </span>
                                </div>
                            </BlokoLink>
                        </div>
                    </Text>
                </li>
            ))}
        </ul>
    );
};

export default translation(RainbowDropCompaniesOfTheDay);
