import { useDispatch } from 'react-redux';

import { ChipsContainer, CustomChip, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { getShortRoleText } from 'src/components/IndexPageAnonymous/Onboarding/utils';

const TrlKeys = {
    title: 'index.onboarding.title.shortRole',
};

const criteriaTextUpdatedAction = makeSetStoreField('criteriaTextUpdated');

interface ShortRolesChipsProps {
    selectedShortRoles: string[];
    setSelectedShortRoles: (roles: string[]) => void;
    isMobile?: boolean;
}

const ShortRolesChips: TranslatedComponent<ShortRolesChipsProps> = ({
    selectedShortRoles,
    setSelectedShortRoles,
    isMobile,
    trls,
}) => {
    const anonymousOnboarding = useSelector(({ anonymousOnboarding }) => anonymousOnboarding);

    const dispatch = useDispatch();

    if (!anonymousOnboarding.isSoft) {
        return null;
    }

    return (
        <VSpacingContainer default={12}>
            <Text style={isMobile ? 'secondary' : 'contrast'}>{trls[TrlKeys.title]}</Text>
            <ChipsContainer scrollable={isMobile} correction={15}>
                {anonymousOnboarding.popularShortRoles.map(({ id, name }) => (
                    <CustomChip
                        key={id}
                        size="small"
                        checked={selectedShortRoles[0] === id}
                        onClick={() => {
                            // в эксперименте soft единичный выбор
                            setSelectedShortRoles([id]);
                            dispatch(
                                criteriaTextUpdatedAction(getShortRoleText(id, anonymousOnboarding.dictShortRoles))
                            );
                        }}
                        style={isMobile ? 'neutral' : 'accent'}
                    >
                        {name}
                    </CustomChip>
                ))}
            </ChipsContainer>
        </VSpacingContainer>
    );
};

export default translation(ShortRolesChips);
