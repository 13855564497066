import { useCallback, useMemo } from 'react';

import { VSpacing, Title, useBreakpoint, Button as ButtonMagritte } from '@hh.ru/magritte-ui';
import { AdjustmentsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import paths from 'src/app/routePaths';
import useToggleSearchPreference from 'src/components/SearchSorts/hooks/useToggleSearchPreference';
import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const SEARCH_RESULT_LIMIT = 10;

const TrlKeys = {
    showMore: 'index.anonymous.vacancies.more',
};

interface SearchResultIndexAnonymousProps {
    title: string;
}

const SearchResultIndexAnonymous: TranslatedComponent<SearchResultIndexAnonymousProps> = ({ trls, title }) => {
    const { isMobile } = useBreakpoint();
    const suitableVacancies = useSelector((state) => state.suitableVacancies);
    const showSearchPreference = useSelector(({ searchPreference }) => searchPreference.isShown);
    const vacancies = (suitableVacancies?.vacancies || []).slice(0, SEARCH_RESULT_LIMIT);
    const fullSearchLink = `${paths.vacancySearch}?${suitableVacancies?.queries?.itemsOnPageBase ?? ''}`;
    const toggleSearchPreference = useToggleSearchPreference();
    const handleToggleFilter = useCallback(() => {
        toggleSearchPreference(!showSearchPreference);
    }, [toggleSearchPreference, showSearchPreference]);

    // Подменяем from в ссылке на вакансию, т.к. первую выдачу вакансий рендерим из "suitable_vacancies" для радужного каталога, а они запрашиваются с другим from_param
    const vacanciesToShow = useMemo(
        () =>
            vacancies.map((vacancy) => ({
                ...vacancy,
                links: {
                    ...vacancy.links,
                    desktop: updateUrl(vacancy.links.desktop, { from: 'anonymous_search_main' }),
                },
            })),
        [vacancies]
    );

    return (
        <>
            <div className={styles.titleWrapper}>
                <Title Element="h2" size={isMobile ? 'small' : 'large'}>
                    {title}
                </Title>
                {isMobile && (
                    <ButtonMagritte
                        icon={<AdjustmentsOutlinedSize24 initial="primary" />}
                        mode="tertiary"
                        hideLabel
                        aria-label="Open filter"
                        onClick={handleToggleFilter}
                    />
                )}
            </div>

            <VSpacing default={24} />
            {vacanciesToShow.map((vacancy) => (
                <VacancySearchItem key={vacancy.vacancyId} vacancy={vacancy} vacancySource={Source.IndexAnonymous} />
            ))}
            <VSpacing default={24} />
            <Button
                data-qa="anonymous-index-search-all-results-button"
                Element={Link}
                to={fullSearchLink}
                kind={ButtonKind.Primary}
                stretched
            >
                {trls[TrlKeys.showMore]}
            </Button>
        </>
    );
};

export default translation(SearchResultIndexAnonymous);
