import { ReactNode } from 'react';

import { Breakpoint, Title, useBreakpoint, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import VacancyOfTheDay from 'src/components/VacancyOfTheDay';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { BELARUS_AREA_ID } from 'src/models/countryId';
import { UserType } from 'src/models/userType';
import { VacancyOfTheDay as VacancyOfTheDayType } from 'src/models/vacanciesOfTheDay';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import { MIN_VACANCIES_NUM_TO_SHOW } from 'src/components/VacanciesOfTheDay/constants';
import useVacanciesOfTheDayViewAnalytics from 'src/components/VacanciesOfTheDay/hooks/useVacanciesOfTheDayViewAnalytics';

import styles from './vacancies-of-the-day.less';

const TrlKeys = {
    titleMain: 'index.vacancies',
    titleIn: 'wic-vod.in',
    titleXS: 'index.headers.vod',
};

interface VacanciesOfTheDayProps {
    userType: UserType;
    spacingBottom?: ReactNode;
}

const VACANCIES_PREVIEW_DEFAULT_COUNT = 8;
const vacanciesPreviewCountForBreakpoint = {
    [Breakpoint.XS]: Infinity,
    [Breakpoint.S]: 4,
    [Breakpoint.M]: 6,
    [Breakpoint.L]: 9,
};

const VacanciesOfTheDay: TranslatedComponent<VacanciesOfTheDayProps> = ({ trls, userType, spacingBottom }) => {
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);
    const domainRegionTrl = useSelector((state) => state.domainRegionTrl);
    const locale = useSelector((state) => state.locale);
    const { breakpoint } = useBreakpoint();
    const isMagritte = useMagritte();

    const vacanciesRefs = useVacanciesOfTheDayViewAnalytics(
        vacanciesOfTheDay.vacancies,
        VacancyOfTheDayLocation.MainPageBottom
    );

    if (!vacanciesOfTheDay.success || vacanciesOfTheDay.vacancies.length <= MIN_VACANCIES_NUM_TO_SHOW) {
        return null;
    }

    const showRegionTrl = !(locale.areaId === BELARUS_AREA_ID && !locale.isSeoDomain);

    if (isMagritte) {
        const renderVOD = (vacancyItem: VacancyOfTheDayType, className?: string) => {
            return (
                <div className={className} key={vacancyItem.vacancyId}>
                    <VacancyOfTheDay
                        topics={vacanciesOfTheDay.topicsByVacancyId[vacancyItem.vacancyId]}
                        vacancy={vacancyItem}
                        sourceQueryParam="vacancies_of_the_day"
                        hhtmFromLabel="vacancies_of_the_day"
                        setVacancyRef={(ref) => {
                            if (ref && vacanciesRefs.current) {
                                vacanciesRefs.current[vacancyItem.vacancyId] = ref;
                            }
                        }}
                    />
                </div>
            );
        };
        const previewCount =
            vacanciesPreviewCountForBreakpoint[breakpoint as keyof typeof vacanciesPreviewCountForBreakpoint] ||
            VACANCIES_PREVIEW_DEFAULT_COUNT;
        return (
            <>
                <Title Element="h3" size="medium">
                    {format(trls[TrlKeys.titleMain], {
                        '{0}': showRegionTrl ? trls[TrlKeys.titleIn] : '',
                        '{1}': showRegionTrl ? domainRegionTrl : '',
                    })}
                </Title>
                <MagritteVSpacing default={16} gteS={20} gteM={24} />
                <IndexCollapsibleBlock
                    renderPreview={() => {
                        return (
                            <div className={styles.wrapper}>
                                {vacanciesOfTheDay.vacancies
                                    .slice(0, previewCount)
                                    .map((vacancy) => renderVOD(vacancy, styles.item))}
                            </div>
                        );
                    }}
                    renderRest={() => {
                        return (
                            <div className={styles.wrapper}>
                                {vacanciesOfTheDay.vacancies
                                    .slice(previewCount)
                                    .map((vacancy) => renderVOD(vacancy, styles.item))}
                            </div>
                        );
                    }}
                    restSpacing={<MagritteVSpacing default={24} />}
                    needToExpand={vacanciesOfTheDay.vacancies.length > previewCount}
                    renderBottomSheetContent={() => {
                        return (
                            <div className={styles.wrapperVertical}>
                                {vacanciesOfTheDay.vacancies.map((vacancy) => renderVOD(vacancy))}
                            </div>
                        );
                    }}
                    bottomSheetTitle={trls[TrlKeys.titleXS]}
                    bottomSheetSubtitle={`${trls[TrlKeys.titleIn]} ${domainRegionTrl}`}
                />
                {spacingBottom}
            </>
        );
    }

    return (
        <div className={`vacancies-of-the-day-${userType}`}>
            <Gap top>
                <H2 Element="h1">
                    {format(trls[TrlKeys.titleMain], {
                        '{0}': showRegionTrl ? trls[TrlKeys.titleIn] : '',
                        '{1}': showRegionTrl ? domainRegionTrl : '',
                    })}
                </H2>
                <VSpacing base={4} />
            </Gap>
            <div className="vacancies-of-the-day">
                {vacanciesOfTheDay.vacancies.map((vacancy) => {
                    return (
                        <div className="vacancies-of-the-day__item" key={vacancy.vacancyId}>
                            <VacancyOfTheDay
                                vacancy={vacancy}
                                sourceQueryParam="vacancies_of_the_day"
                                hhtmFromLabel="vacancies_of_the_day"
                                setVacancyRef={(ref) => {
                                    if (ref && vacanciesRefs.current) {
                                        vacanciesRefs.current[vacancy.vacancyId] = ref;
                                    }
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default translation(VacanciesOfTheDay);
