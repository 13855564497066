import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import { openAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'src/models/anonymousSignUp/hooks';
import {
    ProfessionalRoleCategory,
    ProfessionalRoleCategoryCounted,
} from 'src/models/professionalRoleCategoryCollection';
import { WITH_TOP_FILTER_CATALOG } from 'src/models/topFilterCatalog';

import { ListItemProps } from 'src/components/WorkInProfession/ListItem';
import { sendAnalytics } from 'src/components/WorkInProfession/Utils';

interface ListItemMobileProps extends ListItemProps {
    category: ProfessionalRoleCategory | ProfessionalRoleCategoryCounted;
    positionIndex: number;
    isLast?: boolean;
    isModal?: boolean;
}

const ListItemMobile: FC<ListItemMobileProps> = ({ category, positionIndex, isLast, isModal }) => {
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const dispatch = useDispatch();
    const ref = useRef<HTMLAnchorElement>(null);
    return (
        <li>
            <Text Element="span">
                <BlokoLink
                    kind={LinkKind.Tertiary}
                    Element={Link}
                    to={category.url}
                    additionalQueryParams={{ [WITH_TOP_FILTER_CATALOG]: true }}
                    rel={isModal ? 'nofollow' : undefined}
                    isSeoLink
                    ref={ref}
                    onClick={(event) => {
                        if (mainPageSignUpEnable) {
                            event.preventDefault();
                            dispatch(openAnonymousSignUpModal(ref.current?.href ?? ''));
                        }
                        sendAnalytics(category.id, positionIndex);
                    }}
                >
                    <span
                        className={classnames('easy-tap-link easy-tap-link_with-arrow', {
                            'easy-tap-link_last': isLast,
                        })}
                    >
                        {category.name}
                        {'count' in category && category.count > 0 && (
                            <span className="easy-tap-link-number">{category.count}</span>
                        )}
                    </span>
                </BlokoLink>
            </Text>
        </li>
    );
};

export default ListItemMobile;
