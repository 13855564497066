import { Fragment } from 'react';
import PropTypes from 'prop-types';

const SearchCriterion = ({ children, delimiter = ',', showDelimiter = true }) => {
    return (
        <Fragment>
            {children}
            {showDelimiter && <span className="last-searches-details__delimiter">{`${delimiter} `}</span>}
        </Fragment>
    );
};

SearchCriterion.propTypes = {
    children: PropTypes.node,
    delimiter: PropTypes.string,
    showDelimiter: PropTypes.bool,
};

export default SearchCriterion;
