import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import Compensation from 'src/components/Compensation';
import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT,
    MIN_APPLICANT_SUITABLE_VACANCIES_COUNT,
} from 'src/models/suitableVacancies';
import { UserType } from 'src/models/userType';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import SimilarSavedSearchInfo from 'src/components/RecommendedVacancies/SimilarSavedSearchInfo';

const TrlKeys = {
    titleAnonymous: 'recommendedVacanciesAnonymous.title',
    titleApplicant: 'index.headers.recommended',
    all: 'index.vacancies.all',
};

const RecommendedVacanciesMobile: TranslatedComponent = ({ trls }) => {
    const userType = useSelector((state) => state.userType);
    const suitableVacancies = useSelector((state) => state.suitableVacancies);
    const applicantLatestResume = useSelector((state) => state.applicantLatestResume);
    const enableSimilarSavedSearch = useSelector((state) => state.enableSimilarSavedSearch);
    const applicantInfo = useSelector((state) => state.applicantInfo);

    const isApplicant = userType === UserType.Applicant;
    const resultsFound = suitableVacancies?.resultsFound || 0;

    if (
        (!isApplicant && resultsFound < MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT) ||
        (isApplicant && (resultsFound < MIN_APPLICANT_SUITABLE_VACANCIES_COUNT || !applicantInfo?.total))
    ) {
        return null;
    }

    const latestResumeHash = applicantLatestResume?.resumeHash || '';

    const getResultsFoundLink = () => {
        if (isApplicant) {
            return `${paths.vacancySearch}?resume=${latestResumeHash}`;
        }
        const areas = suitableVacancies?.areaIds?.map((areaId) => `&area=${areaId}`).join('') || '';
        return `${paths.vacancySearch}?recommended_by_uid=true${areas}`;
    };

    const renderClearFiltersSlide = () => {
        if (
            !enableSimilarSavedSearch ||
            suitableVacancies?.resultsFound === undefined ||
            suitableVacancies.resultsFound > 1
        ) {
            return null;
        }
        return (
            <div className="index-slider-item">
                <div className="index-slider-vacancy">
                    <SimilarSavedSearchInfo />
                </div>
            </div>
        );
    };

    return (
        <NoIndex>
            <Column xs="4" s="0" m="0" l="0" container>
                <div
                    className={classnames('index-section-recommended', {
                        'index-section-recommended_spaced': !isApplicant,
                    })}
                >
                    <Column xs="4" s="0" m="0" l="0">
                        <H2>
                            <div className="index-section-header">
                                {trls[isApplicant ? TrlKeys.titleApplicant : TrlKeys.titleAnonymous]}
                            </div>
                        </H2>
                        <Text>
                            <BlokoLink
                                disableVisited
                                Element={Link}
                                to={getResultsFoundLink()}
                                data-qa="index-recommended-link-xs"
                            >
                                {trls[TrlKeys.all]}
                                {NON_BREAKING_SPACE}
                                {resultsFound}
                            </BlokoLink>
                        </Text>
                        <VSpacing base={4} />
                    </Column>
                    <div className="index-slider-wrapper">
                        <div className="index-slider">
                            {suitableVacancies?.vacancies?.map((vacancy) => (
                                <div className="index-slider-item" key={vacancy.vacancyId}>
                                    <Link
                                        to={vacancy.links.desktop}
                                        rel="nofollow"
                                        className="index-slider-vacancy"
                                        data-page-analytics-event="vacancy_search_suitable_item"
                                    >
                                        <span className="index-slider-vacancy-title">
                                            <Text Element="span" strong>
                                                {vacancy.name}
                                            </Text>
                                        </span>
                                        <span className="index-slider-vacancy-compensation">
                                            <Compensation
                                                from={vacancy.compensation.from}
                                                to={vacancy.compensation.to}
                                                currencyCode={vacancy.compensation.currencyCode}
                                            />
                                        </span>
                                        <span className="index-slider-vacancy-company">
                                            <Text importance={TextImportance.Secondary}>
                                                {vacancy.company.visibleName}
                                                {', '}
                                                <span className="index-slider-vacancy-region">{vacancy.area.name}</span>
                                            </Text>
                                        </span>
                                    </Link>
                                </div>
                            ))}
                            {renderClearFiltersSlide()}
                        </div>
                    </div>
                </div>
            </Column>
        </NoIndex>
    );
};

export default translation(RecommendedVacanciesMobile);
