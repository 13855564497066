import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Tabs as MagritteTabs, Tab } from '@hh.ru/magritte-ui';

import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

import { TAB_IDS } from 'src/components/IndexPageEmployer/components/Updates/constants';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const Tabs = ({ trls, activeTabId, onChange = () => {} }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const newTabRef = useRef(null);
    const comingSoonTabRef = useRef(null);

    const handleTabChange = (id) => {
        Analytics.sendEvent('employer', 'click', id);

        if (employerMainPageAnalyticsEnabled) {
            indexPageEmployerAnalytics.updatesTabClick(id);
        }

        onChange(id);
    };

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && newTabRef.current && comingSoonTabRef.current) {
            indexPageEmployerAnalytics.updatesTabShown(
                activeTabId === TAB_IDS.NEW_TAB_ID ? newTabRef.current : comingSoonTabRef.current,
                activeTabId
            );
        }
    }, [activeTabId, employerMainPageAnalyticsEnabled]);

    return (
        <div className="updates-head-tabs">
            <MagritteTabs activeItemId={activeTabId} mode="secondary" onChange={handleTabChange}>
                <Tab id={TAB_IDS.NEW_TAB_ID} dataQa="updates-new" mode="secondary">
                    <span ref={newTabRef}>{trls[Tabs.trls.newTab]}</span>
                </Tab>

                <Tab id={TAB_IDS.COMING_SOON_TAB_ID} dataQa="updates-soon" mode="secondary">
                    <span ref={comingSoonTabRef}>{trls[Tabs.trls.soonTab]}</span>
                </Tab>
            </MagritteTabs>
        </div>
    );
};

Tabs.trls = {
    newTab: 'index.employerUpdatesInfo.new',
    soonTab: 'index.employerUpdatesInfo.soon',
};

Tabs.propTypes = {
    trls: PropTypes.object,
    activeTabId: PropTypes.oneOf(Object.values(TAB_IDS)).isRequired,
    onChange: PropTypes.func,
};

export default translation(Tabs);
