import { useIsRussia } from 'src/hooks/useCountries';
import { useSelector } from 'src/hooks/useSelector';

const WIDGET_DEFAULT_PLACE_NAME = 'employerReviewsSmall';

export const useEmployerReviewsSmallWidgetCanBeRendered = (onlyTrusted = true): boolean => {
    const isRussia = useIsRussia();
    const isTrustedEmployer = useSelector(
        (state) => state.employerInfo.isTrusted || state.vacancyView.company['@trusted']
    );

    return isRussia && ((onlyTrusted && isTrustedEmployer) || !onlyTrusted);
};

export const useEmployerReviewsSmallWidgetHasContent = (onlyTrusted = true): boolean => {
    const canBeRendered = useEmployerReviewsSmallWidgetCanBeRendered(onlyTrusted);
    const widgetStatus = useSelector(({ microFrontends }) => microFrontends[WIDGET_DEFAULT_PLACE_NAME]);
    return !!widgetStatus && canBeRendered;
};
