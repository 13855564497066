import { FC, useRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

import { MobileCompanyItemInterface, getCompanyLink, sendAnalytics } from 'src/components/WorkInCompany/Utils';
import useCompaniesOfTheDayViewAnalytics from 'src/components/WorkInCompany/hooks/useCompaniesOfTheDayViewAnalytics';

const MobileCompanyItem: FC<MobileCompanyItemInterface & PropsWithChildren> = ({ company, isLast }) => {
    const locale = useSelector((state) => state.locale);

    const isZP = useIsZarplataPlatform();
    const companiesOfTheDay = useRef([company]);
    const companiesRefs = useCompaniesOfTheDayViewAnalytics(
        companiesOfTheDay.current,
        WorkInCompanyLocation.MainPageXsPopup
    );

    return (
        <li>
            <BlokoLink
                Element={Link}
                to={getCompanyLink(company, locale, WorkInCompanyLocation.MainPageXsPopup, isZP)}
                kind={LinkKind.Tertiary}
                onClick={() => sendAnalytics(company, 'employer_of_the_day')}
            >
                <span
                    className={classnames('easy-tap-link easy-tap-link_with-arrow', {
                        'easy-tap-link_last': isLast,
                    })}
                    ref={(ref) => {
                        if (ref && companiesRefs.current) {
                            companiesRefs.current[company.id] = ref;
                        }
                    }}
                >
                    <span data-qa="company-of-the-day-name">{company.companyName}</span>
                    <span className="easy-tap-link-number">{company.vacanciesNumber}</span>
                </span>
            </BlokoLink>
        </li>
    );
};

export default MobileCompanyItem;
