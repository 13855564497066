import { FC, PropsWithChildren, useEffect, ComponentProps } from 'react';
import { useDispatch } from 'react-redux';

import { GridLayout, VSpacing, Divider, GridRow, GridColumn, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import AppBanner from 'src/components/AppBanner';
import { SHOW_ADD_AUTH_PHONE_SUCCESS_NOTIFICATION } from 'src/components/Applicant/AddAuthPhone/constants';
import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import FAQ from 'src/components/FAQ';
import DashboardMobileSearch from 'src/components/IndexPageAnonymous/DashboardMobileSearch';
import InternalPromoBlock from 'src/components/InternalPromoBlock';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import NewsBox from 'src/components/NewsBox';
import { useNotification } from 'src/components/Notifications/Provider';
import RainbowCatalog from 'src/components/RainbowCatalog';
import useFetchVacanciesOfTheDay from 'src/components/RainbowCatalog/useFetchVacanciesOfTheDay';
import RsvUserCompetenciesModal from 'src/components/RsvUserCompetencies/RsvUserCompetenciesModal';
import RsvUserCompetenciesNotification from 'src/components/RsvUserCompetencies/RsvUserCompetenciesNotification';
import RsvUserCompetenciesNotificationError from 'src/components/RsvUserCompetencies/RsvUserCompetenciesNotificationError';
import VacanciesOfTheDay from 'src/components/VacanciesOfTheDay';
import WorkInCompany from 'src/components/WorkInCompany';
import WorkInProfession from 'src/components/WorkInProfession';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { BannerObject, BannerPlace } from 'src/models/banners';
import { CompetencyResult } from 'src/models/rsvUserCompetencies';
import { UserType } from 'src/models/userType';

import addAuthPhoneSuccess from 'src/components/IndexPageApplicant/AddAuthPhoneSuccess';
import ApplicantDashboard from 'src/components/IndexPageApplicant/ApplicantDashboard';

const TrlKeys = {
    newsTitle: 'index.newsTitle',
    articlesTitle: 'index.Articles',
};

const Features = {
    rsvAddPage: 'rsv_add_page',
};

const IndexApplicant: TranslatedComponent<FC<PropsWithChildren>> = ({ trls }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const news = useSelector((state) => state.applicantNews);
    const articles = useSelector((state) => state.applicantArticles);
    const applicantFaq = useSelector((state) => state.applicantFaq);
    const bannersHorizontal = useSelector((state) => state.banners[BannerPlace.INDEX_HORIZONTAL]);
    const bannersDashboardMobile = useSelector((state) => state.banners[BannerPlace.INDEX_DASHBOARD_MOBILE]);
    const bannersUnderRainbowMobile = useSelector((state) => state.banners[BannerPlace.INDEX_UNDER_RAINBOW_MOBILE]);
    const bannersUnderNewsBoxMobile = useSelector((state) => state.banners[BannerPlace.INDEX_UNDER_NEWS_BOX_MOBILE]);
    const chatBot = useSelector((state) => state.chatBot);
    const { isXS } = useBreakpoint();
    const isRabotaBy = useIsRabotaBy();
    const siteNewsHref = isRabotaBy ? '/articles/applicants' : '/articles/site-news';

    useFetchVacanciesOfTheDay();

    const enableRSVAddPage = useSelector(({ features }) => features[Features.rsvAddPage] as boolean);
    const { isModalWindowOpened, competenciesResult } = useSelector(({ rsvUserCompetencies }) => rsvUserCompetencies);

    useEffect(() => {
        const authPhoneAddedNotification = LocalStorageWrapper.getItem(SHOW_ADD_AUTH_PHONE_SUCCESS_NOTIFICATION);
        const authPhoneNotificationInfo = authPhoneAddedNotification
            ? (JSON.parse(authPhoneAddedNotification) as { phone: string })
            : null;
        if (authPhoneNotificationInfo) {
            addNotification(addAuthPhoneSuccess, { props: { phoneNumber: authPhoneNotificationInfo.phone } });
            LocalStorageWrapper.removeItem(SHOW_ADD_AUTH_PHONE_SUCCESS_NOTIFICATION);
        }
    }, [addNotification, dispatch]);

    const renderRSVBlock = () => (
        <>
            {enableRSVAddPage && isModalWindowOpened && <RsvUserCompetenciesModal />}
            {enableRSVAddPage && competenciesResult === CompetencyResult.Success && <RsvUserCompetenciesNotification />}
            {enableRSVAddPage && <RsvUserCompetenciesNotificationError />}
        </>
    );

    const renderXSBanners = (
        banners: BannerObject[] | undefined,
        analyticsEvent: string,
        spacingBottom: ComponentProps<typeof VSpacing>['default']
    ) => {
        if (!banners?.length) {
            return null;
        }
        return (
            <GridColumn xs={4} s={0}>
                {banners.map((banner: BannerObject) => (
                    <div key={banner.id} data-page-analytics-event={analyticsEvent}>
                        <AdsExternalBanner {...banner} />
                    </div>
                ))}
                <VSpacing default={spacingBottom} gteS={0} />
            </GridColumn>
        );
    };
    return (
        <MagritteWrapper isEnabled>
            <GridLayout>
                <VSpacing default={0} gteS={40} />
                {isXS && <DashboardMobileSearch />}
                <ApplicantDashboard />
            </GridLayout>
            <VSpacing default={32} gteS={48} gteM={72} />
            <Divider />
            <VSpacing default={16} gteS={48} gteM={72} />
            <GridLayout>
                {bannersDashboardMobile?.length && (
                    <GridRow>
                        {renderXSBanners(bannersDashboardMobile, 'advertising_banner.XsAfterRecommended', 40)}
                    </GridRow>
                )}
                <VacanciesOfTheDay
                    userType={UserType.Applicant}
                    spacingBottom={<VSpacing default={40} gteS={48} gteM={72} />}
                />

                <WorkInCompany isApplicant spacingBottom={<VSpacing default={40} gteS={48} gteM={72} />} />
                {bannersHorizontal?.length && (
                    <GridRow>
                        {bannersHorizontal?.map((banner: BannerObject) => (
                            <GridColumn xs={0} s={4} m={8} l={12} key={banner.id}>
                                <div data-page-analytics-event="advertising_banner.horizontalBanner">
                                    <AdsExternalBanner {...banner} />
                                </div>
                                <VSpacing default={40} />
                            </GridColumn>
                        ))}
                    </GridRow>
                )}
                <AppBanner spacingBottom={<VSpacing default={40} />} />
                <RainbowCatalog spacingBottom={<VSpacing default={40} />} />
                {bannersUnderRainbowMobile?.length && (
                    <GridRow>
                        {renderXSBanners(bannersUnderRainbowMobile, 'advertising_banner.XsAfterRainbow', 40)}
                    </GridRow>
                )}
                <WorkInProfession spacingBottom={<VSpacing default={40} gteS={48} gteM={72} />} />
                <GridRow>
                    <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                        <NewsBox
                            items={news}
                            title={trls[TrlKeys.newsTitle]}
                            href={siteNewsHref}
                            isEmployerContext={false}
                            gapTop
                        />
                        <VSpacing default={16} gteM={24} gteL={0} />
                    </GridColumn>
                    {renderXSBanners(bannersUnderNewsBoxMobile, 'advertising_banner.underNewsBoxMobile', 16)}
                    <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                        <NewsBox
                            items={articles}
                            title={trls[TrlKeys.articlesTitle]}
                            href="/articles"
                            isArticle
                            isEmployerContext={false}
                            gapTop
                        />
                        <VSpacing default={16} gteM={24} gteL={0} />
                    </GridColumn>
                    <GridColumn xs={4} s={8} m={12} l={4} flexibleContent>
                        {!!chatBot && <InternalPromoBlock />}
                    </GridColumn>
                </GridRow>
                <VSpacing default={40} gteS={48} gteM={72} />
                <FAQ items={applicantFaq} spacingBottom={<VSpacing default={40} gteXs={56} gteS={72} />} isApplicant />
            </GridLayout>
            {renderRSVBlock()}
        </MagritteWrapper>
    );
};

export default translation(IndexApplicant);
