import PropTypes from 'prop-types';

import { Text } from '@hh.ru/magritte-ui';

const ItemText = ({ date, text }) => {
    return (
        <div>
            <div className="updates-text">
                <Text typography="label-2-regular">{text}</Text>
            </div>

            {date && (
                <Text typography="label-3-regular" style="secondary">
                    {date}
                </Text>
            )}
        </div>
    );
};

ItemText.propTypes = {
    date: PropTypes.string,
    text: PropTypes.string.isRequired,
};

export default ItemText;
