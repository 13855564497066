import { useMemo, useState } from 'react';

import { Card, Divider, Tab, Tabs, Title, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import CalendarContent from 'src/components/IndexPageEmployer/components/Blog/CalendarContent';
import NewsOrArticlesContent from 'src/components/IndexPageEmployer/components/Blog/NewsOrArticlesContent';

enum TabEnum {
    NEWS = 'news',
    ARTICLES = 'articles',
    CALENDAR = 'calendar',
}

const BLOCK_LINKS: Record<TabEnum, string> = {
    [TabEnum.NEWS]: '/articles/site-news',
    [TabEnum.ARTICLES]: '/articles/employers',
    [TabEnum.CALENDAR]: '/articles/events',
};

const TrlKeys = {
    title: 'index.blog.title',
    [TabEnum.NEWS]: 'index.blog.news.title',
    [TabEnum.ARTICLES]: 'index.blog.articles.title',
    [TabEnum.CALENDAR]: 'index.blog.calendar.title',
};

const Blog: TranslatedComponent = ({ trls }) => {
    const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.NEWS);

    const employerNews = useSelector((state) => state.employerNews);
    const employerArticles = useSelector((state) => state.employerArticles);
    const { eventsMain } = useSelector((state) => state.employerIndexPage.oldEmployer) || { eventsMain: null };
    const activityItems = useMemo(() => eventsMain?.body?.events || [], [eventsMain?.body?.events]);

    const tabList = useMemo(() => {
        const list: TabEnum[] = [TabEnum.NEWS, TabEnum.ARTICLES];

        if (activityItems.length) {
            list.push(TabEnum.CALENDAR);
        }

        return list;
    }, [activityItems.length]);

    const content = useMemo(() => {
        const href = BLOCK_LINKS[activeTab];

        switch (activeTab) {
            case TabEnum.ARTICLES:
                return <NewsOrArticlesContent isArticle href={href} items={employerArticles} />;
            case TabEnum.NEWS:
                return <NewsOrArticlesContent href={href} items={employerNews} />;
            case TabEnum.CALENDAR:
                return <CalendarContent items={activityItems} href={href} />;
            default:
                return null;
        }
    }, [activeTab, activityItems, employerArticles, employerNews]);

    return (
        <Card showBorder padding={24} borderRadius={24} stretched>
            <Title Element="h2" size="small" data-qa="blog-title">
                {trls[TrlKeys.title]}
            </Title>
            <Tabs
                data-qa="blog-tabs"
                activeItemId={activeTab}
                onChange={(tabId) => setActiveTab(tabId as TabEnum)}
                mode="secondary"
            >
                {tabList.map((tab) => {
                    return (
                        <Tab data-qa={`${tab}-tab`} key={tab} id={tab}>
                            {trls[TrlKeys[tab]]}
                        </Tab>
                    );
                })}
            </Tabs>
            <Divider />
            <VSpacing default={24} />
            {content}
        </Card>
    );
};

export default translation(Blog);
