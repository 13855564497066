import { FC, PropsWithChildren, useEffect, useState } from 'react';

import styles from './circle-progress.less';

interface CircleSvgProps {
    progress: number;
}

const SVG_SIZE = 48;
const STROKE_WIDTH = 4;

const CircleProgress: FC<PropsWithChildren<CircleSvgProps>> = ({ progress = 0, children }) => {
    const [currentProgress, setCurrentProgress] = useState<number>(progress);
    const radius = SVG_SIZE / 2;
    const insetRadius = radius - STROKE_WIDTH / 2;
    const strokeDasharray = Math.PI * (insetRadius * 2);
    const strokeDashoffset = currentProgress * strokeDasharray;

    useEffect(() => {
        setCurrentProgress(progress);
    }, [progress]);

    return (
        <div className={styles.container}>
            <svg width="100%" height="100%" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle
                    className={styles.circleBackground}
                    r={insetRadius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeDasharray="0"
                    strokeDashoffset="0"
                    strokeWidth={STROKE_WIDTH}
                />
                <circle
                    className={styles.circleProgress}
                    r={insetRadius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    transform={`rotate(-90,${radius},${radius})`}
                    strokeWidth={STROKE_WIDTH}
                    strokeLinecap="round"
                />
            </svg>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

export default CircleProgress;
