import { useState } from 'react';
import PropTypes from 'prop-types';

import { ChipsContainer, CustomChip, Divider, Link, Text, VSpacing } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import style from './prepared-vacancy-templates.less';

const PreparedVacancyTemplates = ({ trls }) => {
    const push = usePush();
    const templates = useSelector(({ preparedVacancyTemplates }) => preparedVacancyTemplates);
    const [visibleCount, setVisibleCount] = useState(12);
    const [loadMoreVisible, setLoadMoreVisible] = useState(templates.length - visibleCount > 0);

    return templates.length > 0 ? (
        <>
            <div className={style.wrapper} data-qa="dashboard-vacancy-prepared-templates">
                <Text typography="title-4-semibold">{trls[PreparedVacancyTemplates.trls.title]}</Text>
                <VSpacing default={8} />
                <Text typography="paragraph-2-regular" style="secondary">
                    {trls[PreparedVacancyTemplates.trls.subtitle]}
                </Text>
                <VSpacing default={24} />
                <div className={style.list}>
                    <ChipsContainer>
                        {templates.slice(0, visibleCount).map((template) => (
                            <CustomChip
                                Element="button"
                                onClick={() =>
                                    push(
                                        `/employer/vacancy/create?from=index_new_employer&preparedTemplateId=${template.id}`
                                    )
                                }
                                key={template.id}
                                data-qa="dashboard-vacancy-prepared-templates-item"
                            >
                                {template.name}
                            </CustomChip>
                        ))}
                    </ChipsContainer>
                </div>
                {loadMoreVisible && (
                    <>
                        <VSpacing default={24} />
                        <Link
                            typography="label-2-regular"
                            style="accent"
                            onClick={() => {
                                setVisibleCount(templates.length);
                                setLoadMoreVisible(false);
                            }}
                        >
                            {formatToReactComponent(trls[PreparedVacancyTemplates.trls.loadMore], {
                                '{0}': (
                                    <ConversionNumber
                                        many={trls[PreparedVacancyTemplates.trls.templatesFive]}
                                        some={trls[PreparedVacancyTemplates.trls.templatesTwo]}
                                        one={trls[PreparedVacancyTemplates.trls.templatesOne]}
                                        zero={trls[PreparedVacancyTemplates.trls.templatesFive]}
                                        value={templates.length - visibleCount}
                                    />
                                ),
                            })}
                        </Link>
                    </>
                )}
            </div>
            <Divider />
        </>
    ) : null;
};

PreparedVacancyTemplates.propTypes = {
    trls: PropTypes.object,
};

PreparedVacancyTemplates.trls = {
    title: 'newemployer.dashboard.preparedVacancyTemplates.title',
    subtitle: 'newemployer.dashboard.preparedVacancyTemplates.subtitle',
    loadMore: 'newemployer.dashboard.preparedVacancyTemplates.loadMoreButton',
    templatesFive: 'words.template.5',
    templatesTwo: 'words.template.2',
    templatesOne: 'words.template.1',
};

export default translation(PreparedVacancyTemplates);
