import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';

type Error = null | AxiosError | unknown;
type Request<Payload, Response> = (payload: Payload) => Promise<Response>;

type UseFetchOptions<Payload, Response> = {
    onSuccess?: (response: Response, payload: Payload) => void;
    onError?: (error: Error) => void;
};
type FetchRequest<Payload, Response> = (payload?: Payload) => Promise<Response | Error>;

export interface UseFetchReturn<Payload, Response> {
    loading: boolean;
    isError: boolean;
    error: Error;
    isSuccess: boolean;
    response: Response | null;
    fetchRequest: FetchRequest<Payload, Response>;
}

const useFetch = <Payload, Response>(
    request: Request<Payload, Response>,
    options?: UseFetchOptions<Payload, Response>
): UseFetchReturn<Payload, Response> => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>(null);
    const [response, setResponse] = useState<Response | null>(null);

    const { onSuccess, onError } = options || {};

    const fetchRequest = useCallback<FetchRequest<Payload, Response>>(
        async (body) => {
            try {
                setLoading(true);
                const response = await request(body as Payload);
                onSuccess?.(response, body as Payload);
                setResponse(response);
                setError(null);
                return response;
            } catch (error) {
                onError?.(error);
                setResponse(null);
                setError(error);
                return error;
            } finally {
                setLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onError, onSuccess, request]
    );

    return { loading, isError: !!error, error, isSuccess: !!response, response, fetchRequest };
};

export type { FetchRequest };
export default useFetch;
