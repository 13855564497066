// tempexp_35925-file
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Text, Card, Cell, CellText, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './contact-phones-tempexp.less';

const TrlKeys = {
    contactUsHeader: 'employer.auth.contactUs.ifNeedHelp',
    contactUsSubheader: 'employer.order.phoneAndConsult',
    contactUsAction: 'employer.order.phone',
    regions: {
        msk: 'helpPhone.title.mskAndRegion',
        spb: 'helpPhone.title.spbAndRegion',
        regions: 'helpPhone.title.otherRegions',
        city: 'helpPhone.title.city',
        mobile: 'helpPhone.title.mobile',
        other: 'helpPhone.title.regions',
    },
};

const ContactPhonesTempexp: TranslatedComponent = ({ trls }) => {
    const phones = useSelector(({ contactPhones }) => contactPhones) || [];
    const { isXS } = useBreakpoint();

    return (
        <>
            <VSpacing default={isXS ? 28 : 40} />
            <div className={styles.cardWrapper}>
                <Card stretched verticalStretched showBorder borderRadius={24} padding={12}>
                    <div className={styles.content}>
                        <div data-qa="contact-us" className={styles.text}>
                            <Text typography="title-5-semibold">{trls[TrlKeys.contactUsHeader]}</Text>
                            <VSpacing default={8} />
                            <Text typography="paragraph-2-regular" style="secondary">
                                {trls[TrlKeys.contactUsSubheader]}
                            </Text>
                            <VSpacing default={24} />
                            <div className={styles.phones}>
                                {phones.map((phone) => (
                                    <div key={phone.postfix}>
                                        <Cell>
                                            <CellText type="subtitle">{trls[TrlKeys.regions[phone.postfix]]}</CellText>
                                            <Link to={`tel:${phone.country}${phone.city}${phone.number}`}>
                                                <CellText>{`${phone.country} ${phone.city} ${phone.number}`}</CellText>
                                            </Link>
                                        </Cell>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Button
                            Element={Link}
                            to="/support/order_call/list"
                            data-qa="contact-us-order-call"
                            style="neutral"
                            mode="secondary"
                            size="medium"
                            stretched
                        >
                            {trls[TrlKeys.contactUsAction]}
                        </Button>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default translation(ContactPhonesTempexp);
