import { useMemo, FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import { useSelectorNonNullable } from 'src/hooks/useSelector';

import Header from 'src/components/WorkInProfession/Header';
import ListItem from 'src/components/WorkInProfession/ListItemMobile';
import ModalGroup from 'src/components/WorkInProfession/ModalGroupMobile';
import { WorkInProfessionProps } from 'src/components/WorkInProfession/index';

const WorkInProfession: FC<WorkInProfessionProps> = ({ customTitle, disableTitleLink }) => {
    const { title, collection, collectionCounted } = useSelectorNonNullable(
        (state) => state.professionalRoleCategoryCollection
    );

    const collectionOrCounted = collectionCounted.length ? collectionCounted : collection;
    const collectionToRender = useMemo(() => collectionOrCounted.slice(0, 10), [collectionOrCounted]);

    return (
        <>
            <Header text={customTitle || title} disableTitleLink={disableTitleLink} />
            <VSpacing base={4} />
            <ul data-qa="index__work-in-profession-list">
                {collectionToRender.map((category, index) => (
                    <ListItem key={category.id} category={category} positionIndex={index} />
                ))}
            </ul>
            <VSpacing base={4} />
            <ModalGroup title={title} collection={collectionOrCounted} />
        </>
    );
};

export default WorkInProfession;
