import Analytics from '@hh.ru/analytics-js';

export default {
    myEventsVacancyResponsesClick: (): void => Analytics.sendHHEventButtonClick(`my_events_vacancy_responses`),
    myEventsVacancyResponsesShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_vacancy_responses`,
        });
    },

    myEventsAutoSearchClick: (): void => Analytics.sendHHEventButtonClick(`my_events_auto_search`),
    myEventsAutoSearchShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_auto_search`,
        });
    },

    myEventsBalanceAccountClick: (): void => Analytics.sendHHEventButtonClick(`my_events_balance_account`),
    myEventsBalanceAccountShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_balance_account`,
        });
    },

    myEventsBalanceIncreaseClick: (): void => Analytics.sendHHEventButtonClick(`my_events_balance_increase`),
    myEventsBalanceIncreaseShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_balance_increase`,
        });
    },

    myEventsVacanciesClick: (): void => Analytics.sendHHEventButtonClick(`my_events_vacancies`),
    myEventsVacanciesShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_vacancies`,
        });
    },

    myEventsVacanciesBuyClick: (): void => Analytics.sendHHEventButtonClick(`my_events_vacancies_buy`),
    myEventsVacanciesBuyShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_vacancies_buy`,
        });
    },

    myEventsDatabaseAccessClick: (): void => Analytics.sendHHEventButtonClick(`my_events_database_access`),
    myEventsDatabaseAccessShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_database_access`,
        });
    },

    myEventsDatabaseAccessBuyClick: (): void => Analytics.sendHHEventButtonClick(`my_events_database_access_buy`),
    myEventsDatabaseAccessBuyShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_database_access_buy`,
        });
    },

    myEventsCreateVacancyClick: (): void => Analytics.sendHHEventButtonClick(`my_events_create_vacancy`),
    myEventsCreateVacancyShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `my_events_create_vacancy`,
        });
    },

    howToSearchLearnMoreClick: (): void => Analytics.sendHHEventButtonClick(`how_to_search_learn_more`),
    howToSearchLearnMoreShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `how_to_search_learn_more`,
        });
    },

    updatesTabClick: (item: string): void => Analytics.sendHHEventButtonClick(`updates_tab_${item}`),
    updatesTabShown: (element: HTMLElement, item: string): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `updates_tab_${item}`,
        });
    },

    updatesAllClick: (): void => Analytics.sendHHEventButtonClick(`updates_all`),
    updatesAllShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `updates_all`,
        });
    },

    activitiesTitleClick: (): void => Analytics.sendHHEventButtonClick(`activities_title`),
    activitiesTitleShown: (element: HTMLElement): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `activities_title`,
        });
    },

    activitiesItemClick: (item: string): void =>
        Analytics.sendHHEventButtonClick(`activities_item`, {
            item,
        }),
    activitiesItemsShown: (element: HTMLElement, items: string[]): void => {
        Analytics.sendHHEventElementShown(element, {
            name: `activities_items`,
            item: items,
        });
    },
};
