import { ReactNode, FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';

import Compensation from 'src/components/Compensation';
import SearchDebug from 'src/components/Debug/SearchDebug';
import RainbowTileDrop from 'src/components/RainbowCatalog/Drop';
import CompanyLink from 'src/components/VacancyCompanyLink';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

interface RainbowTileVacancyProps {
    vacancy: VacancySearchItem;
    tileIndex: number;
    activeDropIndex: number;
    activeTileContent: ReactNode;
    previousActiveTileIndex: number;
}

const RainbowTileVacancy: FC<RainbowTileVacancyProps> = ({
    vacancy,
    tileIndex,
    activeDropIndex,
    activeTileContent,
    previousActiveTileIndex,
}) => {
    return (
        <>
            <div className="dashboard-tiles-item dashboard-tiles-item_suitable-vacancies" data-rainbow-catalog-tile="">
                <Column xs="0" s="4" m="3" l="3">
                    <div
                        className="dashboard-tiles-item-suitable-vacancy-title"
                        data-qa="neva-vacancy-item-desktop-title"
                    >
                        <H3Section lite>
                            <BlokoLink Element={Link} to={vacancy.links.desktop}>
                                <span className="dashboard-tiles-item-suitable-vacancy-title-link">{vacancy.name}</span>
                            </BlokoLink>
                        </H3Section>
                    </div>
                    <div className="dashboard-tiles-item__salary">
                        <Compensation
                            from={vacancy.compensation.from}
                            to={vacancy.compensation.to}
                            currencyCode={vacancy.compensation.currencyCode}
                        />
                    </div>
                    <div className="dashboard-tiles-item-suitable-vacancy-company">
                        <Text importance={TextImportance.Tertiary}>
                            <CompanyLink vacancy={vacancy}>
                                {`${vacancy.company.name}, ${vacancy.area.name}`}
                            </CompanyLink>
                        </Text>
                    </div>
                    {vacancy.searchDebug && <SearchDebug {...vacancy.searchDebug} />}
                </Column>
            </div>
            <RainbowTileDrop
                tileIndex={tileIndex}
                activeDropIndex={activeDropIndex}
                activeTileContent={activeTileContent}
                previousActiveTileIndex={previousActiveTileIndex}
            />
        </>
    );
};

export default RainbowTileVacancy;
