import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import Compensation from 'src/components/Compensation';
import { getVacancyLink } from 'src/components/VacanciesOfTheDay/Utils';
import { sendAnalytics } from 'src/components/VacanciesOfTheDay/analytics';
import useVacanciesOfTheDayViewAnalytics from 'src/components/VacanciesOfTheDay/hooks/useVacanciesOfTheDayViewAnalytics';
import CompanyLink from 'src/components/VacancyCompanyLink';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyOfTheDay } from 'src/models/vacanciesOfTheDay';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

interface RainbowDropVacanciesOfTheDayProps {
    vacancies: VacancyOfTheDay[];
}

const RainbowDropVacanciesOfTheDay: FC<RainbowDropVacanciesOfTheDayProps> = ({ vacancies }) => {
    const vacanciesRefs = useVacanciesOfTheDayViewAnalytics(vacancies, VacancyOfTheDayLocation.RainbowCatalog);
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    return (
        <ul className="multiple-column-list multiple-column-list_wide">
            {vacancies.map((vacancy) => (
                <li className="multiple-column-list-item" key={vacancy.vacancyId}>
                    <Text>
                        <div className="dashboard-tiles-drop-item">
                            <BlokoLink
                                Element={Link}
                                to={getVacancyLink(vacancy, VacancyOfTheDayLocation.RainbowCatalog)}
                                additionalQueryParams={{
                                    source: 'rainbowCatalog',
                                    from: hhtmSource,
                                    hhtmFromLabel: 'recommended_vacancies',
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => sendAnalytics(vacancy.vacancyId, 'rainbow_catalog')}
                            >
                                <span
                                    className="vacancy-of-the-day__title"
                                    data-qa="vacancy_of_the_day_title"
                                    ref={(ref) => {
                                        if (ref && vacanciesRefs.current) {
                                            vacanciesRefs.current[vacancy.vacancyId] = ref;
                                        }
                                    }}
                                >
                                    {vacancy.name}
                                </span>
                            </BlokoLink>
                            <div className="dashboard-tiles-drop-compensation dashboard-tiles-drop-compensation_promo">
                                <span className="vacancy-of-the-day__salary" data-qa="vacancy_of_the_day_compensation">
                                    <Compensation
                                        from={vacancy.compensation.from}
                                        to={vacancy.compensation.to}
                                        currencyCode={vacancy.compensation.currencyCode}
                                        showNoSalaryMessage
                                    />
                                </span>
                            </div>
                            {vacancy.company.id && (
                                <div>
                                    <span className="vacancy-of-the-day__company" data-qa="vacancy_of_the_day_company">
                                        <CompanyLink vacancy={vacancy}>
                                            {vacancy.company.visibleName && `${vacancy.company.visibleName}, `}
                                            {vacancy.area.name}
                                        </CompanyLink>
                                    </span>
                                </div>
                            )}
                        </div>
                    </Text>
                </li>
            ))}
        </ul>
    );
};

export default translation(RainbowDropVacanciesOfTheDay);
