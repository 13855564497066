import { useEffect, useRef, useState } from 'react';

import { GridColumn, GridRow, VSpacing } from '@hh.ru/magritte-ui';

import AdditionalServices from 'src/components/IndexPageEmployer/components/AdditionalServices';
import Blog from 'src/components/IndexPageEmployer/components/Blog';
import EmployerEventList from 'src/components/IndexPageEmployer/components/EmployerEventList';
import LastSearches from 'src/components/IndexPageEmployer/components/LastSearches';
import Updates from 'src/components/IndexPageEmployer/components/Updates';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

const DEFAULT_WIDGET_HEIGHT = 360;

const Dashboard = () => {
    const isZp = useIsZarplataPlatform();
    const ref = useRef(null);
    const [widgetHeight, setWidgetHeight] = useState(DEFAULT_WIDGET_HEIGHT);
    const isRussia = useIsRussia();

    useEffect(() => {
        const { height } = ref.current?.getBoundingClientRect();

        if (height) {
            setWidgetHeight(height);
        }
    }, []);

    return (
        <>
            <VSpacing default={40} />

            <GridRow rowGap={24}>
                <GridColumn xs={4} s={8} m={6} l={3} flexibleContent>
                    <EmployerEventList ref={ref} />
                </GridColumn>

                {!isZp && isRussia ? (
                    <GridColumn flexibleContent xs={4} s={8} m={0}>
                        <AdditionalServices />
                    </GridColumn>
                ) : null}

                <GridColumn xs={4} s={8} m={6} l={3} flexibleContent>
                    <LastSearches maxHeight={widgetHeight} />
                </GridColumn>
                {isZp ? (
                    <GridColumn xs={4} s={8} m={12} l={6}>
                        <Blog />
                    </GridColumn>
                ) : (
                    <>
                        <GridColumn xs={4} s={8} m={12} l={isRussia ? 3 : 6} flexibleContent>
                            <Updates maxHeight={widgetHeight} />
                        </GridColumn>
                        {isRussia && (
                            <GridColumn flexibleContent xs={0} s={0} m={0} l={3}>
                                <AdditionalServices />
                            </GridColumn>
                        )}
                    </>
                )}
            </GridRow>
            <VSpacing default={24} l={24} />
        </>
    );
};

export default Dashboard;
