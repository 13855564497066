import { FC, PropsWithChildren, MouseEvent } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

const DATA_QA = 'professions-item-mobile';

interface RainbowTileMobileProps {
    onClick: (title: string, event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
    link?: string;
    professionIndex: number;
    colorIndex?: number;
    profession?: string;
}

const RainbowTileMobile: FC<RainbowTileMobileProps & PropsWithChildren> = ({
    children,
    colorIndex,
    link,
    onClick,
    professionIndex = 0,
    profession = '',
}) => {
    const breakpoint = useBreakpoint(Breakpoint.M);
    const resolution = breakpoint === Breakpoint.XS ? 'mobile' : 'desktop';

    if (link) {
        return (
            <Link
                to={link}
                className={'dashboard-tiles-item-mobile dashboard-tiles-item-link'}
                onClick={(event) => onClick(String(children), event)}
                data-qa={DATA_QA}
            >
                {children}
            </Link>
        );
    }

    return (
        <div
            className={classnames('dashboard-tiles-item-mobile', {
                [`dashboard-tiles-item-mobile_rainbow-${String(colorIndex)}`]: colorIndex !== undefined,
            })}
            data-page-analytics-event={`vacancy_rainbow_catalog_item.${resolution}`}
            data-page-analytics-position={professionIndex}
            data-page-analytics-value={profession}
            onClick={(event) => onClick(String(children), event)}
            data-qa={DATA_QA}
        >
            {children}
        </div>
    );
};

export default RainbowTileMobile;
