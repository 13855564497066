import { useEffect } from 'react';

import { UXFeedback } from 'src/utils/uxfeedback';

import { useSelector } from 'src/hooks/useSelector';

export const useSendMagritteResumeSerpUxFb = (): void => {
    const isMagritteResumeSerp = useSelector((state) => state.isMagritteResumeSerp);

    useEffect(() => {
        if (isMagritteResumeSerp) {
            UXFeedback.sendEvent('resume_search_quality_employer_magritte');
        }
    }, [isMagritteResumeSerp]);
};
