import { useEffect, ReactNode } from 'react';

import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdvertisementAgreement from 'src/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import useShowAdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/useShowAdvertisementSubscriptionApplicant';
import SignUpForm, { SignUpSignFormBaseProps } from 'src/components/ApplicantSignInSignUpFlow/SignUpStep/SignUpForm';
import translation from 'src/components/translation';

const TrlKeys = {
    header1: 'signUpStep.header.1',
    header2: 'signUpStep.header.2',
};

export interface SignUpStepProps extends SignUpSignFormBaseProps {
    setHeader: (headerContent: ReactNode) => void;
}

const SignUpStep: TranslatedComponent<SignUpStepProps> = ({ trls, setHeader, ...formProps }) => {
    const showAdvertisementAgreement = useShowAdvertisementAgreement();
    const showAdvertisementSubscriptionApplicant = useShowAdvertisementSubscriptionApplicant();

    useEffect(() => {
        setHeader(
            <Text size={TextSize.Large} strong>
                {trls[TrlKeys.header1]}
                <br />
                {trls[TrlKeys.header2]}
            </Text>
        );
    }, [setHeader, trls]);

    return (
        <SignUpForm
            render={({
                renderFirstNameField,
                renderLastNameField,
                renderAccountDeleteInactive,
                renderSubmit,
                renderAdvertisementAgreement,
                renderAdvertisementSubscriptionApplicant,
            }) => {
                return (
                    <>
                        {renderFirstNameField()}
                        <VSpacing base={2} />
                        {renderLastNameField()}
                        <VSpacing base={4} />
                        {renderAccountDeleteInactive()}
                        {showAdvertisementSubscriptionApplicant && (
                            <>
                                {renderAdvertisementSubscriptionApplicant()}
                                <VSpacing base={6} />
                            </>
                        )}
                        {renderSubmit()}
                        {showAdvertisementAgreement && (
                            <div>
                                <Column l="5" m="5" s="5" xs="4" container>
                                    <VSpacing base={5} />
                                    {renderAdvertisementAgreement()}
                                </Column>
                            </div>
                        )}
                    </>
                );
            }}
            backurl="/applicant/resumes/new"
            isSignupMain
            {...formProps}
        />
    );
};

export default translation(SignUpStep);
