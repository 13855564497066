import useFetch, { FetchRequest } from 'src/hooks/useFetch';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/price/free_service/activate';

type ServiceCode = string;
type Payload = { serviceCode: ServiceCode };
type Response = {
    activatedServices: {
        employerServiceId: number;
    }[];
};

declare global {
    interface FetcherPutApi {
        [URL]: {
            queryParams: void;
            body: Payload;
            response: Response;
        };
    }
}

type UseActivateFreeServiceProps = {
    onSuccess?: () => void;
    onError?: () => void;
};

const useActivateFreeService = (
    props?: UseActivateFreeServiceProps
): [FetchRequest<ServiceCode, Response>, boolean] => {
    const { fetchRequest, loading } = useFetch<ServiceCode, Response>(
        async (serviceCode) => {
            const response = await fetcher.put(URL, { serviceCode });
            return response.data;
        },
        {
            onSuccess: props?.onSuccess,
            onError: props?.onError,
        }
    );

    return [fetchRequest, loading];
};

export default useActivateFreeService;
