import { ProfessionsListProfession } from 'src/models/professionsList';

export const PART_TIME_CATALOG_ID = '50';

interface GetVacancyCompanyLinkParams {
    company: {
        id: number;
        department?: {
            '@code': string;
        };
    };
}

export const getVacancyCompanyLink = (vacancy: GetVacancyCompanyLinkParams): string => {
    let link = `/employer/${vacancy.company.id}`;
    if (vacancy.company.department) {
        link += `?dpt=${vacancy.company.department['@code']}`;
    }
    return link;
};

export const getProfessionLink = (
    parentProfession: ProfessionsListProfession,
    profession: ProfessionsListProfession
): string => {
    if (profession.catalogPath) {
        return profession.catalogPath;
    }
    const url = parentProfession.id === PART_TIME_CATALOG_ID ? '/vacancies/podrabotka' : '/search/vacancy';
    return `${url}?${profession.query}`;
};
