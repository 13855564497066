import { ReactNode, FC } from 'react';

import Modal, { ModalHeader, ModalTitle, ModalContent } from 'bloko/blocks/modal';

import translation from 'src/components/translation';

interface RainbowModalProps {
    isVisible: boolean;
    onClose: () => void;
    content: { title: string; body: ReactNode; separator?: ReactNode } | null;
}

const RainbowModal: FC<RainbowModalProps> = ({ isVisible, onClose, content }) => {
    if (!content) {
        return null;
    }
    return (
        <Modal visible={isVisible} onClose={onClose}>
            <ModalHeader data-qa="professions-modal-mobile-page">
                <ModalTitle>{content.title}</ModalTitle>
                {content.separator || null}
            </ModalHeader>
            <ModalContent data-qa="professions-modal-mobile-content">{content.body}</ModalContent>
        </Modal>
    );
};

export default translation(RainbowModal);
