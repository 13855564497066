import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Title, Text, Button, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const ZP_ARTICLE_URL = '/article/25295?hhtmFrom=resume_search_form';
const HH_ARTICLE_URL = '/article/hhsearch';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const EmptySearches = ({ trls }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const howToSearchLearnMoreRef = useRef(null);
    const resumeCount = useSelector((state) => state.globalStatistics?.resumes);
    const isZp = useIsZarplataPlatform();
    const showResumeCount = !useIsRabotaBy();

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && howToSearchLearnMoreRef.current) {
            indexPageEmployerAnalytics.howToSearchLearnMoreShown(howToSearchLearnMoreRef.current);
        }
    }, [employerMainPageAnalyticsEnabled]);

    return (
        <div data-qa="last-searches-empty">
            <div ref={howToSearchLearnMoreRef} className="last-searches">
                <Title Element="h2" size="small">
                    {trls[EmptySearches.trls.emptySearchesTitle]}
                </Title>

                <VSpacing default={24} />

                <Text typography="paragraph-2-regular" style="secondary">
                    {showResumeCount && resumeCount && (
                        <>
                            <p>
                                {format(trls[EmptySearches.trls.resumesCount], {
                                    '{0}': NumberFormatter.format(`${resumeCount}`, {
                                        groupSeparator: NON_BREAKING_SPACE,
                                    }),
                                })}
                            </p>
                            <VSpacing default={8} />
                        </>
                    )}
                    <p>{trls[EmptySearches.trls.queryConstructor]}</p>
                </Text>

                <VSpacing default={24} />

                <Button
                    Element={Link}
                    to={isZp ? ZP_ARTICLE_URL : HH_ARTICLE_URL}
                    data-qa="last-searches-empty-findout"
                    onClick={indexPageEmployerAnalytics.howToSearchLearnMoreClick}
                    mode="secondary"
                    stretched
                >
                    {trls[EmptySearches.trls.findOut]}
                </Button>
            </div>
        </div>
    );
};

EmptySearches.trls = {
    emptySearchesTitle: 'index.employerLastSearches.textBlockTitle',
    resumesCount: 'index.employerLastSearches.text.resumesCount',
    queryConstructor: 'index.employerLastSearches.text.queryConstructor',
    findOut: 'index.employerLastSearches.text.findOut',
};

EmptySearches.propTypes = {
    trls: PropTypes.object,
    resumeCount: PropTypes.number,
};

export default translation(EmptySearches);
