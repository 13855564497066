import { useEffect, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Text as MagritteText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { MinusCircleFilledSize24, CheckCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Notification from 'bloko/blocks/notificationManager/Notification';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useActivateFreeService from 'src/api/price/useActivateFreeService';
import paths from 'src/app/routePaths';
import Snackbar from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useFetch from 'src/hooks/useFetch';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import style from './style.less';

const HIDE_NOTIFICATION_URL = '/shards/price/free_service/hide_notification';

declare global {
    interface FetcherPutApi {
        [HIDE_NOTIFICATION_URL]: {
            queryParams: void;
            body: void;
            response: void;
        };
    }
}

const SERVICE_NAME = 'super_vppl';

const TrlKeys = {
    successSnackbarText: 'free-super-vppl-banner.success-snackbar-text',
    successSnackbarLink: 'free-super-vppl-banner.success-snackbar-link',
    somethingWrong: 'something-wrong',
    title: 'free-super-vppl-notification.title',
    text: 'free-super-vppl-notification.text',
    activate: 'free-super-vppl-notification.activate-button',
};

const FreeVpplNotification: TranslatedComponent = ({ trls }) => {
    const showFreeVpplNotification = useSelector(({ showFreeVpplNotification }) => showFreeVpplNotification);
    const [visible, setVisible] = useState(showFreeVpplNotification);

    const { fetchRequest: hideNotification } = useFetch<void, void>(async () => {
        const response = await fetcher.put(HIDE_NOTIFICATION_URL);
        return response.data;
    });

    const handleClose = () => {
        Analytics.sendHHEventButtonClick('Astrakhan_banner_close');
        setVisible(false);
        void hideNotification();
    };

    const { addNotification } = useNotification();

    const [activateFreeService, loading] = useActivateFreeService({
        onSuccess: () => {
            setVisible(false);
            addNotification(Snackbar, {
                props: {
                    children: (
                        <>
                            <MagritteText typography="label-2-regular" style="contrast">
                                {trls[TrlKeys.successSnackbarText]}
                            </MagritteText>
                            <VSpacing base={4} />
                            <MagritteLink
                                Element={Link}
                                typography="subtitle-1-semibold"
                                style="contrast"
                                to={paths.vacancyCreate}
                            >
                                {trls[TrlKeys.successSnackbarLink]}
                            </MagritteLink>
                        </>
                    ),
                    showClose: true,
                    addon: <CheckCircleOutlinedSize24 initial="positive" />,
                },
            });
        },
        onError: () => {
            Analytics.sendHHEventButtonClick('Astrakhan_banner_close');

            setVisible(false);
            addNotification(Snackbar, {
                props: {
                    children: <>{trls[TrlKeys.somethingWrong]}</>,
                    showClose: false,
                    addon: <MinusCircleFilledSize24 initial="negative" />,
                },
            });
        },
    });

    const activateFreeVppl = () => {
        void activateFreeService(SERVICE_NAME);
        Analytics.sendHHEventButtonClick('Astrakhan_banner_activate');
    };

    useEffect(() => {
        if (visible) {
            Analytics.sendHHEvent('element_shown', { elementName: 'Astrakhan_banner_shown' });
        }
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <Notification onClose={handleClose}>
            <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
            <VSpacing base={1} />
            <Text importance={TextImportance.Primary}>
                <span className={style.freeVpplNotificationBody}>{trls[TrlKeys.text]}</span>
            </Text>
            <NotificationFooter>
                <Button
                    appearance={ButtonAppearance.Outlined}
                    loading={loading && <Loading scale={LoadingScale.Small} />}
                    onClick={activateFreeVppl}
                >
                    {trls[TrlKeys.activate]}
                </Button>
            </NotificationFooter>
        </Notification>
    );
};

export default translation(FreeVpplNotification);
