/**
 * Утилита следит за видимостью элемента на странице, и запускает калбеки при изменении состояния. <br>
 *
 * @type {Object}
 * @exports Utils/VisibilityWatcher
 */
const visibilityWatcher = {
    /**
     * Инициализирует вотчер на элементе.
     *
     * @param {Object}   options
     * @param {Element}  options.element   DOM элемент за которым наблюдаем
     * @param {Function} options.onVisible Функция, которая будет вызвана, когда элемент отобразится
     *
     * @member
     * @method
     */
    init(options) {
        let visible = false;
        const element = options.element;
        const checkVisible = () => {
            if (visible) {
                return;
            }
            if (element.offsetWidth || element.offsetHeight || element.getClientRects().length) {
                visible = true;
                options.onVisible();
                window.removeEventListener('resize', checkVisible);
                window.removeEventListener('element-displayed', checkVisible);
                element.dataset.listenersSetted = 'false';
            } else {
                visible = false;
            }
        };
        if (options.element.dataset.listenersSetted !== 'true') {
            window.addEventListener('resize', checkVisible);
            window.addEventListener('element-displayed', checkVisible);
            options.element.dataset.listenersSetted = 'true';
        }
        checkVisible();
    },
};
export default visibilityWatcher;
