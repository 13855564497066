import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import { PART_TIME_CATALOG_ID, getProfessionLink } from 'src/components/RainbowCatalog/utils';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';
import { ProfessionsListProfession } from 'src/models/professionsList';

interface RainbowDropProfessionProps {
    professions: ProfessionsListProfession[];
    parentProfession: ProfessionsListProfession;
    currency: CurrencyType;
    showSeoLinksForBots: boolean;
}

const TrlKeys = {
    vacancies: {
        one: 'professions.vacancies.one',
        some: 'professions.vacancies.some',
        many: 'professions.vacancies.many',
    },
};

const RainbowDropProfession: TranslatedComponent<RainbowDropProfessionProps> = ({
    professions,
    parentProfession,
    currency,
    showSeoLinksForBots,
    trls,
}) => {
    return (
        <ul className="multiple-column-list multiple-column-list_wide">
            {[parentProfession, ...professions].map((profession, index) => {
                return (
                    // Поисковым ботам показываем только ЧПУ (ссылки на каталог /vacancies/) и подработку
                    (showSeoLinksForBots
                        ? profession.catalogPath || parentProfession.id === PART_TIME_CATALOG_ID
                        : true) && (
                        <li className="multiple-column-list-item" key={profession.id}>
                            <Text>
                                <div className="dashboard-tiles-drop-item">
                                    <BlokoLink
                                        Element={Link}
                                        to={getProfessionLink(parentProfession, profession)}
                                        additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                                        isSeoLink={showSeoLinksForBots}
                                        data-qa="profession-item-title"
                                        data-page-analytics-value={profession.name}
                                        data-page-analytics-position={index}
                                        data-page-analytics-event="vacancy_rainbow_catalog_item_link.rainbowLink"
                                    >
                                        {profession.name}
                                    </BlokoLink>
                                </div>
                                <br />
                                <div className="dashboard-tiles-drop-compensation">
                                    <Compensation
                                        from={profession.compensationFrom}
                                        to={profession.compensationTo}
                                        currencyCode={currency}
                                        dash
                                    />
                                    <span className="dashboard-tiles-drop-comma">, </span>
                                    <div className="dashboard-tiles-drop-vacancies-number">
                                        <Text importance={TextImportance.Tertiary}>
                                            <Conversion
                                                value={profession.count}
                                                one={trls[TrlKeys.vacancies.one]}
                                                some={trls[TrlKeys.vacancies.some]}
                                                many={trls[TrlKeys.vacancies.many]}
                                            />
                                        </Text>
                                    </div>
                                </div>
                            </Text>
                        </li>
                    )
                );
            })}
        </ul>
    );
};

export default translation(RainbowDropProfession);
