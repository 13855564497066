import PropTypes from 'prop-types';

import { Button, Text, Card, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import styles from './contact-phones.less';

const ContactPhones = ({ trls }) => {
    const phones = useSelector(({ contactPhones }) => contactPhones) || [];
    const isZp = useIsZarplataPlatform();
    const { isXS } = useBreakpoint();

    return (
        <div className={styles.cardWrapper}>
            <Card stretched verticalStretched showBorder borderRadius={24} padding={12}>
                <div className={styles.content}>
                    <div data-qa="contact-us" className={styles.text}>
                        <Text typography="title-5-semibold">{trls[ContactPhones.trls.contactUsHeader]}</Text>
                        <div className={styles.phones}>
                            {phones.map((phone) => (
                                <div key={phone.postfix}>
                                    {!isZp && (
                                        <Text typography="label-3-regular" style="secondary">
                                            {trls[ContactPhones.trls.regions[phone.postfix]]}
                                        </Text>
                                    )}
                                    {isXS ? (
                                        <Text
                                            Element="a"
                                            href={`tel:${phone.country}${phone.city}${phone.number}`}
                                            typography="label-2-regular"
                                        >
                                            {`${phone.country} ${phone.city} ${phone.number}`}
                                        </Text>
                                    ) : (
                                        <Text Element="span" typography="label-2-regular">
                                            {`${phone.country} ${phone.city} ${phone.number}`}
                                        </Text>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {!isZp && (
                        <Button
                            Element={Link}
                            to="/support/order_call/list"
                            data-qa="contact-us-order-call"
                            style="neutral"
                            mode="secondary"
                            size="medium"
                            stretched
                        >
                            {trls[ContactPhones.trls.contactUsAction]}
                        </Button>
                    )}
                </div>
            </Card>
        </div>
    );
};

ContactPhones.trls = {
    contactUsHeader: 'employer.auth.contactUs.needHelp',
    contactUsAction: 'employer.order.phone',
    regions: {
        msk: 'helpPhone.title.msk',
        spb: 'helpPhone.title.spb',
        regions: 'helpPhone.title.regions',
        city: 'helpPhone.title.city',
        mobile: 'helpPhone.title.mobile',
        other: 'helpPhone.title.regions',
    },
};

ContactPhones.propTypes = {
    trls: PropTypes.object,
    showDelimiter: PropTypes.bool,
};

export default translation(ContactPhones);
