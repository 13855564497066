import { FC } from 'react';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import DashboardBigPromoFooter from 'src/components/IndexPageAnonymous/DashboardBigPromoFooter';
import DashboardFooter from 'src/components/IndexPageAnonymous/DashboardFooter';
import DashboardSearch from 'src/components/IndexPageAnonymous/DashboardSearch';

const Dashboard: FC = () => {
    const dashboard = useSelector(({ supernovaAnonymousDashboard }) => supernovaAnonymousDashboard);
    const userType = useSelector(({ userType }) => userType);
    const isOnboardingExp = useSelector(({ anonymousOnboarding }) => anonymousOnboarding.isOnboardingExp);
    const isRedesignAnonymousMenuExpB = useExperiment('redesign_anonymous_menu_v2_b', true, false);
    const isRedesignAnonymousMenuExpC = useExperiment('redesign_anonymous_menu_v2_c', true, false);
    const isRedesignAnonymousMenuExpD = useExperiment('redesign_anonymous_menu_v2_d', true, false);

    const isRedesignActive =
        userType === UserType.Anonymous &&
        (isRedesignAnonymousMenuExpB || isRedesignAnonymousMenuExpC || isRedesignAnonymousMenuExpD);

    return (
        <div
            className={classnames('supernova-dashboard supernova-dashboard_optimized-menu', {
                'supernova-dashboard_optimized-menu-redesign': isRedesignActive,
                [`supernova-dashboard_bg-applicant-${dashboard.backgroundScope}-${dashboard.backgroundIndex}`]:
                    dashboard.backgroundScope,
                [`supernova-dashboard_big-promo-${dashboard.bigPromoEmployerId}`]: dashboard.bigPromoEmployerId,
                'supernova-dashboard_backoffice': userType === UserType.BackOffice,
            })}
        >
            <div className="supernova-dashboard-content">
                <ColumnsWrapper>
                    <Column xs="0" s="0" m={isOnboardingExp ? '1' : '0'} l={isOnboardingExp ? '3' : '2'} />
                    <Column xs="0" s="8" m="10" l="10">
                        <DashboardSearch />
                    </Column>
                    <Column xs="0" s="8" m="12" l="16">
                        {dashboard.bigPromoEmployerId ? <DashboardBigPromoFooter /> : <DashboardFooter />}
                    </Column>
                </ColumnsWrapper>
            </div>
        </div>
    );
};

export default Dashboard;
