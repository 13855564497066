import { VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import { useSelector } from 'src/hooks/useSelector';
import { EmployerState } from 'src/models/employerStatus';

import CompanyConfirmation from 'src/components/IndexPageNewEmployer/components/CompanyConfirmation';
import ContactPhones from 'src/components/IndexPageNewEmployer/components/ContactPhones';
import DashboardVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/DashboardVacancy';

import styles from './index-page-new-employer.less';

const IndexNewEmployer = () => {
    const { category, state } = useSelector(({ employerStatus }) => employerStatus);
    // tempexp_35925_start
    const verificationExpEnabled = useSelector(({ verificationExpEnabled }) => verificationExpEnabled);
    const { draft, bill, nextDraftScheduled } = useSelector((state) => state.newEmployerDashboardVacancy);
    const bigHelpEnabled = verificationExpEnabled && ![draft, bill, nextDraftScheduled].some(Boolean);
    // tempexp_35925_end
    const isEmployerStatusNew = state === EmployerState.New;

    return (
        <ColumnsWrapper>
            <div className={styles.wrapper}>
                <Column xs="4" s="8" m="12" l="16">
                    <DashboardVacancy bigHelpEnabled={bigHelpEnabled} />
                    <VSpacing default={40} />
                    <div className={styles.support}>
                        {!bigHelpEnabled && (
                            <>
                                <ContactPhones />
                                {isEmployerStatusNew && <CompanyConfirmation companyCategory={category} />}
                            </>
                        )}
                    </div>
                    <VSpacing default={72} />
                </Column>
            </div>
        </ColumnsWrapper>
    );
};

export default IndexNewEmployer;
