import { createContext, useContext, FC, PropsWithChildren, useMemo } from 'react';

type SignupTrlsContextType = {
    signin: {
        header?: string;
    };
};

const SignupTrlsContext = createContext<SignupTrlsContextType>({ signin: {} });

export const useSignupTrlsContextContext = (): SignupTrlsContextType => useContext(SignupTrlsContext);

export const SignupTrlsContextProvider: FC<PropsWithChildren<SignupTrlsContextType>> = ({ children, signin }) => {
    const value = useMemo(() => ({ signin }), [signin]);
    return <SignupTrlsContext.Provider value={value}>{children}</SignupTrlsContext.Provider>;
};
