import { SupernovaSearchName, SupernovaSearchNameAnalyticsMapping } from 'src/models/supernovaSearchName';

const SHORT_ROLE_LOGIC_DELIMITER = ' OR ';
const SHORT_ROLE_COMPOSITED_DELIMITER = ',';

export const DUMMY_SHORT_ROLE_ID = 'DUMMY_ROLE';

export const isCompositedRole = (role: string): boolean => role.includes(SHORT_ROLE_COMPOSITED_DELIMITER);

export const getCompositedShortRoleText = (role: string): string =>
    role.replace(SHORT_ROLE_COMPOSITED_DELIMITER, SHORT_ROLE_LOGIC_DELIMITER);

export const getShortRoleText = (id: string, dict: Record<string, string>): string => {
    if (id === DUMMY_SHORT_ROLE_ID) {
        return '';
    }
    const roleText = dict[id];
    if (isCompositedRole(roleText)) {
        return getCompositedShortRoleText(roleText);
    }
    return roleText;
};

export const getShortRolesText = (roles: string[], dict: Record<string, string>): string =>
    roles
        .map((id) => getShortRoleText(id, dict))
        .filter((text) => !!text)
        .join(SHORT_ROLE_LOGIC_DELIMITER);

export const getOnboardingLabel = (roles: string[], schedule: string[]): string => {
    if (roles.length > 0 && schedule.length > 0) {
        return 'vacancy_search_onboarding_full';
    }
    if (roles.length > 0) {
        return 'vacancy_search_onboarding_with_role';
    }
    if (schedule.length > 0) {
        return 'vacancy_search_onboarding_with_schedule';
    }
    return SupernovaSearchNameAnalyticsMapping[SupernovaSearchName.Vacancies];
};
