import { FC, useEffect, useRef } from 'react';

import { Link, Text, VSpacingContainer } from '@hh.ru/magritte-ui';

import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import { useIsRussia } from 'src/hooks/useCountries';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { ArticleItemWithDate } from 'src/models/article.types';

import ShowAllLink from 'src/components/IndexPageEmployer/components/Blog/ShowAllLink';

import styles from './styles.less';

interface CalendarContentProps {
    href: string;
    items: ArticleItemWithDate[];
}

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const MAXIMUM_ITEMS_COUNT = 3;

const CalendarContent: FC<CalendarContentProps> = ({ href, items }) => {
    const isRussia = useIsRussia();
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const activitiesRef = useRef(null);

    const activityItems = items.slice(0, MAXIMUM_ITEMS_COUNT);

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && activitiesRef.current) {
            indexPageEmployerAnalytics.activitiesTitleShown(activitiesRef.current);
            indexPageEmployerAnalytics.activitiesItemsShown(
                activitiesRef.current,
                activityItems.map((item) => item.text)
            );
        }
    }, [employerMainPageAnalyticsEnabled, activityItems]);

    const handleClick = () => {
        if (employerMainPageAnalyticsEnabled) {
            indexPageEmployerAnalytics.activitiesTitleClick();
        }
    };

    const showMoreLink = isRussia ? (
        <ShowAllLink
            data-qa="calendar-show-more-link"
            href={href}
            onClick={employerMainPageAnalyticsEnabled ? handleClick : undefined}
        />
    ) : null;

    return (
        <div data-qa="calendar-content" ref={activitiesRef} className={styles.content}>
            <VSpacingContainer default={24}>
                {activityItems.map(({ href, date, text }) => (
                    <div key={href}>
                        <Text data-qa="calendar-date" typography="label-3-regular" style="secondary">
                            {date}
                        </Text>
                        <Link
                            data-qa="calendar-link"
                            href={href}
                            style="neutral"
                            onClick={
                                employerMainPageAnalyticsEnabled
                                    ? () => indexPageEmployerAnalytics.activitiesItemClick(text)
                                    : undefined
                            }
                        >
                            <Text maxLines={1} typography="label-2-regular">
                                {text}
                            </Text>
                        </Link>
                    </div>
                ))}
            </VSpacingContainer>
            {showMoreLink}
        </div>
    );
};

export default CalendarContent;
