import { PropsWithChildren, useRef } from 'react';

import { useCollapsible } from '@hh.ru/magritte-ui';

interface CollapsibleProps {
    expanded?: boolean;
    deps?: unknown[];
}

const Collapsible: React.FC<PropsWithChildren<CollapsibleProps>> = ({ children, expanded = false, deps }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { collapsibleClasses } = useCollapsible(ref, expanded, deps);

    return (
        <div ref={ref} data-qa="collapsible" className={collapsibleClasses}>
            {children}
        </div>
    );
};

export default Collapsible;
