import Analytics from '@hh.ru/analytics-js';
import { H2 } from 'bloko/blocks/header';
import Link, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'index.workInOtherCities.header',
};

const WorkInOtherCities: TranslatedComponent = ({ trls }) => {
    const citiesList = useSelector((state) => state.workInOtherCity?.link);

    if (!citiesList) {
        return null;
    }

    return (
        <div className="work-in-other-cities">
            <div className="work-in-other-cities__header">
                <H2 Element="h3">{trls[TrlKeys.title]}</H2>
                <VSpacing base={4} />
            </div>
            <ul>
                {citiesList?.map((item, index) => (
                    <li className="multiple-column-list-item" key={item.id}>
                        <Text Element={'span'}>
                            <Link
                                href={`//${item.domain}`}
                                kind={LinkKind.Tertiary}
                                onClick={() => {
                                    Analytics.sendHHEventButtonClick('switch_area', {
                                        areaId: item.id,
                                        position: index,
                                    });
                                    Analytics.sendHHEventButtonClick('vacancy_area_catalog_list', {
                                        domainId: item.id,
                                    });
                                }}
                                suppressHydrationWarning
                            >
                                <span className="easy-tap-link easy-tap-link_with-arrow">{item.name}</span>
                            </Link>
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default translation(WorkInOtherCities);
